import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent } from 'primereact/fileupload';

interface PropsUpload {
    request?: string, 
    setFile: any
}

const UploadFile = ({ request, setFile }: PropsUpload) => {


    const onTemplateSelect = (event: FileUploadSelectEvent) => {
        let files = event.files;
        setFile(files)
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton, cancelButton } = options;
        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-upload', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined', };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div>
            <FileUpload
                name="demo[]"
                cancelOptions={cancelOptions}
                chooseOptions={chooseOptions}
                headerTemplate={headerTemplate}
                onSelect={onTemplateSelect}
                url={request}
                accept="/*"
                maxFileSize={15000000}
                emptyTemplate={<p className="m-0">Arraste e solte arquivos aqui para fazer upload.</p>}
            />
        </div>
    )
}

export default UploadFile;
