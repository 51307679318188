import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useContext, useRef, useState } from "react";
import { Button, Tooltip } from "../../../../ludz.one_ui";
import LudzCardFile from "../../../../ludz.one_ui/Card/CardFile";
import DashbordModalFiles from "../DashbordModalFiles";
import DeleteFile from "./DeleteFile";
import { SubZonaInfoContextType } from "../../../../contexts/Subzone/SubzoneInfo/type";
import { SubzonaInfoContext } from "../../../../contexts/Subzone/SubzoneInfo/context";
import { Toast } from "primereact/toast";

// import { Container } from './styles';

interface Painel {
  subzone: any;
}

interface Arquivos {
  aws: {
    isThumbnail: boolean;
    url: string;
  };
  createdAt: string;
  id: number;
  original_name: string;
  size: number;
}

const AreaUploads = ({ subzone }: Painel) => {
  const dt = useRef<DataTable<Arquivos[]>>(null);
  const { toastarchive } = useContext(SubzonaInfoContext) as SubZonaInfoContextType
  const [visibleFile, setVisibleFile] = useState(false);
  

  // Funções de Arquivos no Painel

  const nameBodyTemplate = (rowData: Arquivos) => {
    return (
      <div className="flex align-items-center">
        <LudzCardFile
          link={`${rowData.aws.url}`}
          title={rowData.original_name}
        />
      </div>
    );
  };

  const dateBodyTemplate = (rowData: Arquivos) => {
    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    const formatDate = () => {
      if (rowData.createdAt) {
        const date = new Date(rowData.createdAt);

        var day: any = date.getDate();

        if (day < 10) {
          day = "0" + day;
        }

        var year = date.getFullYear();

        var postDate =
          day + " " + months[date.getMonth()].substring(0, 3) + " " + year;
        return postDate;
      } else {
        return "Não possui data";
      }
    };

    return <span>{formatDate()}</span>;
  };

  const fileSizeBodyTemplate = (rowData: Arquivos) => {
    function bytesParaKilobytes(bytes: number): number {
      return bytes / 1024;
    }

    function kilobytesParaMegabytes(kilobytes: number): number {
      return kilobytes / 1024;
    }

    const convertSize = () => {
      if (rowData.size > 1024 && rowData.size < 1048575) {
        return `${bytesParaKilobytes(rowData.size).toFixed(2)} kb`;
      } else if (rowData.size >= 1048576) {
        return `${kilobytesParaMegabytes(
          bytesParaKilobytes(rowData.size)
        ).toFixed(2)} mb`;
      }

      return `${rowData.size} bytes`;
    };

    return <span>{convertSize()}</span>;
  };

  const uploadBodyCenter = (rowData: Arquivos) => {
    return (
      <div className="text-center">
        <DeleteFile item={rowData} />
      </div>
    );
  };

  return (
    <div className="card" style={{ marginTop: "-12px" }}>
     <>
     <Toast ref={toastarchive}/>
          <div className="card-title mb-3">
            <h5>Arquivos Enviados</h5>
          </div>
          <Tooltip title="Envio de Arquivos" arrow>
            <Button
              icon="pi pi-upload"
              className="p-button-rounded mx-3 mb-3"
              onClick={() => {
                setVisibleFile(true);
                // setIdSecao(item._id);
              }}
            ></Button>
          </Tooltip>
          <DataTable
            ref={dt}
            value={subzone.archives!}
            paginator
            rows={3}
            scrollable
            scrollHeight="flex"
          >
            <Column
              field="name"
              header="Name"
              sortable
              sortField="original_name"
              body={nameBodyTemplate}
              headerStyle={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="date"
              header="Date"
              body={dateBodyTemplate}
              headerClassName="white-space-nowrap"
              headerStyle={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="fileSize"
              header="File Size"
              body={fileSizeBodyTemplate}
              sortable
              sortField="size"
              headerStyle={{ minWidth: "12rem" }}
            ></Column>
            <Column body={uploadBodyCenter} style={{ width: "10rem" }}></Column>
          </DataTable>
          <DashbordModalFiles
            visible={visibleFile}
            onOpen={() => setVisibleFile(!visibleFile)}
          />
        </>
    </div>
  );
};

export default AreaUploads;
