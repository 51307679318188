import { ChangeEvent, useRef } from "react";
import { Button } from "../../../ludz.one_ui";
import { InputContainer } from "../IAstyles";

interface ChatInputProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  handleSend: (text: string) => void;
  loading: boolean | undefined;
}

const ChatInput = ({ inputValue, setInputValue, handleSend, loading }: ChatInputProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      if (textareaRef.current.scrollHeight > 120) {
        textareaRef.current.style.height = "120px";
        textareaRef.current.style.overflowY = "auto";
      } else {
        textareaRef.current.style.overflowY = "hidden";
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    adjustTextareaHeight();
  };

  const handleSendMessage = () => {
    handleSend(inputValue);
    setInputValue(""); // Limpa o input após o envio
    adjustTextareaHeight(); // Redimensiona o textarea
  };

  return (
    <InputContainer
      style={{
        display: "flex",
        alignItems: "center",
        padding: "8px",
        borderRadius: "10px",
        boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f7f7f8",
      }}
    >
      <textarea
        ref={textareaRef}
        value={inputValue}
        onChange={handleChange}
        onKeyDown={(e) => e.key === "Enter" && !e.shiftKey && handleSendMessage()}
        placeholder="Escreva sua mensagem aqui..."
        style={{
          resize: "none",
          overflowY: "hidden",
          width: "100%",
          minHeight: "40px",
          maxHeight: "120px",
          border: "none",
          outline: "none",
          padding: "12px",
          fontSize: "16px",
          borderRadius: "10px",
          backgroundColor: "white",
        }}
      />
      <Button
        onClick={handleSendMessage}
        style={{
          marginLeft: "8px",
          minWidth: "40px",
          height: "40px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          color: "white",
          cursor: "pointer",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
        }}
        icon={loading ? "pi pi-stop-circle" : "pi pi-send"}
      />
    </InputContainer>
  );
};

export default ChatInput;
