import { useNavigate, useParams } from "react-router-dom";
import { ContentSimple } from "../../../../contexts/Channel/ChannelComplet/type";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../styles/globalStyles";
import { ContentCard } from "./style";

const Contents = ({ item }: { item: Array<ContentSimple> }) => {
  return (
    <>
      {item?.map((item: ContentSimple, index: number) => {
        return <ContentComponent item={item} key={index} />;
      })}
    </>
  );
};

const ContentComponent = ({ item }: { item: ContentSimple }) => {
  const history = useNavigate();
  const { id } = useParams();

  return (
    <ContentCard onClick={() => {history(`/canal/${id}/${item.id}`); window.location.reload()}}>
      <Padding padding="8px">
        <Row>
          <Column id="center">
            <i className="pi pi-video"></i>
          </Column>
          <Padding />
          <h5>{item.name}</h5>
        </Row>
      </Padding>
    </ContentCard>
  );
};

export default Contents;
