import { Message } from "../../contexts/PrivateRoutes/types";
import http from "../../services/api";

export const handleSendMessage = async (
  messages: Message[],
  setMessages: any,
  setInput: any,
  setLoading?: any
) => {
  await http
    .post(
      "/bff/gpt-bff/send", {messages: messages}
    )
    .then((response) => {
        console.log(response)
      setMessages([
        ...messages,
        response.data
      ]);

      // Clear the input field
      setInput("");
      setLoading(false);
    })
    .catch((error) => alert(error));

  // Update the conversation history with the response from ChatGPT
};
