import { useMutation } from "react-query";
import queryClient from "../../../../../services/react-query";
import { ArchivesKanbanCardRequest, DeleteArchivesKanbanCardRequest } from "./request";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../../../components/Alert";
import { verifySizeZone } from "../../../../../services/upload-archives";
import { useParams } from "react-router-dom";

export const ArchivesKanbanCardController = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {

    const { id } = useParams();
    const idzona = parseInt(id!)

    const ArchivesKanbanCardMutation = useMutation(async ({data, id}:{data: File, id: string}) => {
        if (data) {
            return await verifySizeZone(data, idzona).then((item) => {
                return ArchivesKanbanCardRequest(data, id)
            }).catch((error) => {
                throw new Error("UploadLimitExceeded");
            })
        }
        
    
    }, {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            if (error.message === "UploadLimitExceeded") {
                Alert({
                    summary: "Atenção",
                    detail: "A zona excedeu o limite de upload",
                    severity: "warn",
                    toast: toast
                }).show();
            } else {
                Alert({
                    summary: "Erro",
                    detail: "Aconteceu algum problema, tente novamente",
                    severity: "error",
                    toast: toast
                }).show();
                console.log(error);
            }
            console.log(error)
        },
        onSuccess: (result) => {
            Alert({
                detail: "Arquivo enviado!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });

    const DeleteArchivesKanbanCardMutation = useMutation((id: string) => DeleteArchivesKanbanCardRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            Alert({ detail: "Error", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
            Alert({
                detail: "Arquivo excluido!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseSubzoneKanbanbff")
            queryClient.refetchQueries("UseSubzoneKanbanCard")

        },
    });


    return{
        ArchivesKanbanCardMutation,DeleteArchivesKanbanCardMutation
    }
}