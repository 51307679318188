import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Activities from "../../../../components/Activities";
import CardNotifications from "../../../../components/CardNotifications";
import ViewHTML from "../../../../components/ViewHTML";
import { PrivateRoutesContext } from "../../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../../contexts/PrivateRoutes/types";
import { PostSubzonaContext } from "../../../../contexts/Subzone/MuralSubzone/context";
import { PostSubzonaContextType } from "../../../../contexts/Subzone/MuralSubzone/type";
import { Progress, Tooltip } from "../../../../ludz.one_ui";
import { getId } from "../../../../services/localStorage";
import AreaUploads from "../AreaUploads";
import { AreaSobre } from "./style";

// import { Container } from './styles';

interface Painel {
  subzone: any;
}

const SubzonePainel = ({ subzone }: Painel) => {
  const { id, idSubzone } = useParams();
  const history = useNavigate();

  const { posts } = useContext(PostSubzonaContext) as PostSubzonaContextType;
  const { notifications } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;
  const [, setVisible] = useState<boolean>(false);

  const notificationSubZone = notifications?.filter((notification) => {
    if (!notification?.links?.length) {
      return false;
    }
    const link = notification.links[0];
    return link?.subZone_fk?.toString() === idSubzone;
  });


  const postReverse = posts ? [...posts].reverse() : [];


  const totalMembros = () => {
    let total = subzone?.members?.length;
    let valor;

    if (total > 6) {
      return (valor = total - 6);
    } else {
      return valor;
    }
  };

  const displayedMembers = subzone?.members?.slice(0, 6);


  return (
    <div className="layout-dashboard" style={{ marginTop: '-20px' }}>
      <div className="grid">
        <div className="col-12 lg:col-6">
          <div className="grid">
            <div className="col-12">
              <AreaSobre
                className="card device-status"
                
              >
                <div className="grid">
                  <div className="col-12 xl:col-9">
                    <div className="card-header">
                      <div className="card-title">
                        <h5>Sobre</h5>
                      </div>
                    </div>
                    <ViewHTML item={subzone?.description.toString()} />
                  </div>
                </div>
              </AreaSobre>

              {/* Area para colocar arquivos */}
             
            </div>
          </div>
        </div>

        <div className="col-12 lg:col-6">
          <div className="grid">
            <div className="col-12 md:col-6">
              <div className="card timeline">
                <div className="card-header">
                  <div className="card-title">
                    <h5>Atividades</h5>
                    <h6 className="subtitle">Todas da Zona</h6>
                  </div>
                </div>
                <ul>
                  {posts ? (
                    <>
                      {postReverse.map((item: any, index: any) => {
                        return <Activities item={item} key={index} />;
                      })}
                    </>
                  ) : (
                    <Progress />
                  )}
                </ul>
                <button
                  onClick={() =>
                    history(`/zona/${id}/subzona/${idSubzone}/mural`)
                  }
                  className="p-link"
                >
                  +Detalhes
                </button>
              </div>
            </div>

            <div className="col-12 md:col-6">
              <div className="card timeline px-3">
                <div className="card-header">
                  <div className="card-title">
                    <h5>Notificações</h5>
                    <h6 className="subtitle">Todas notificações</h6>
                  </div>
                </div>
                <ul>
                  {notifications ? (
                    <>
                      {notificationSubZone?.map((item: any, index: any) => {
                        return (
                          <CardNotifications key={index} notification={item} />
                        );
                      })}
                    </>
                  ) : (
                    <Progress />
                  )}
                </ul>
                <button
                  onClick={() => history(`/perfil/${getId()}`)}
                  className="p-link"
                >
                  +Detalhes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12" style={{  padding: '0px' }}>
      <div className="col-12 desktop-teams mb-5" style={{ padding: '0px' }}>
              <div className="card team">
                <div className="card-header">
                  <div className="card-title">
                    <h5>Membros</h5>
                    <h6 className="subtitle">
                      {subzone?.members?.length > 1
                        ? `${subzone?.members?.length} pessoas`
                        : `${subzone?.members?.length} pessoa`}
                    </h6>
                  </div>
                </div>
                <div className="peoples">
                  {subzone?.members.length <= 6 ? (
                    subzone?.members.map((item: any, index: any) => {
                      return (
                        <>
                          <Tooltip title={item?.user?.username} arrow>
                            <img
                              src={item?.user?.aws?.url}
                              alt="freya-layout"
                            />
                          </Tooltip>
                        </>
                      );
                    })
                  ) : (
                    <>
                      {displayedMembers.map((item: any, index: any) => {
                        return (
                          <>
                            <Tooltip title={item?.user?.username} arrow>
                              <img
                                src={item?.user?.aws?.url}
                                alt="freya-layout"
                              />
                            </Tooltip>
                          </>
                        );
                      })}
                      <div className="no-picture ">
                        <span
                          onClick={() => setVisible(true)}
                        >{`+${totalMembros()}`}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 mobile-teams mb-5" style={{ padding: '0px' }}>
              <div className="card team">
                <div className="card-header">
                  <div className="card-title">
                    <h5>Membros</h5>
                    <h6 className="subtitle">
                      {subzone?.members?.length > 1
                        ? `${subzone?.members?.length} pessoas`
                        : `${subzone?.members?.length} pessoa`}
                    </h6>
                  </div>
                </div>
                <div className="peoples">
                  {subzone?.members.length <= 6 ? (
                    subzone?.members.map((item: any, index: any) => {
                      return (
                        <>
                          <Tooltip title={item?.user?.username} arrow>
                            <img
                              src={item?.user?.aws?.url}
                              alt="freya-layout"
                            />
                          </Tooltip>
                        </>
                      );
                    })
                  ) : (
                    <>
                      {displayedMembers.map((item: any, index: any) => {
                        return (
                          <>
                            <Tooltip title={item?.user?.username} arrow>
                              <img
                                src={item?.user?.aws?.url}
                                alt="freya-layout"
                              />
                            </Tooltip>
                          </>
                        );
                      })}
                      <div className="no-picture ">
                        <span
                          onClick={() => setVisible(true)}
                        >{`+${totalMembros()}`}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
      <AreaUploads subzone={subzone} />
      </div>
    </div>
  );
};

export default SubzonePainel;
