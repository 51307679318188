import { useMediaQuery } from "@mui/material";
import { ButtonAlternative, GridAwnsers } from "../IAstyles";
import { useState } from "react";


export interface OptionsAwnsers {
  icon: string;
  color: string;
  awnser: string;
}

interface ChatOptionsProps {
  handleSendButton: (text: string) => void;
  optionsAwnsers: OptionsAwnsers[];
  topbar?: boolean
}

const ChatOptions = ({ handleSendButton, optionsAwnsers, topbar }: ChatOptionsProps) => {
  const [showAll, setShowAll] = useState(false);
  const matches1024p = useMediaQuery("(min-width: 920px) and (max-width: 1366px)")
  const matches = useMediaQuery("(max-width: 520px)")
  const matchesMobile = useMediaQuery("(max-width: 390px)");

  const maxOptions = matchesMobile ? 3 :  (matches1024p || matches) ? 6 : optionsAwnsers.length;
  const displayedOptions = showAll ? optionsAwnsers : optionsAwnsers.slice(0, maxOptions);

  return (
    <>
    <GridAwnsers>
      {displayedOptions.map((item: OptionsAwnsers, index: number) => (
        <ButtonAlternative key={index}>
          <i className={item.icon} style={{ color: item.color }}></i>
          <p onClick={() => handleSendButton(item.awnser)}>{item.awnser}</p>
        </ButtonAlternative>
      ))}
      
    </GridAwnsers>
    {(matches1024p || matches) && !showAll && !topbar &&
      (
        <h3 className="cursor-pointer" onClick={() => setShowAll(true)}> <i className="pi pi-angle-double-down" /> </h3>
      )
      }
      {showAll && ( <h3 className="cursor-pointer" onClick={() => setShowAll(false)}><i className="pi pi-angle-double-up" /></h3> )}
    </>
    
  );
};

export default ChatOptions;
