import { useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import { ChannelsPublicContext } from "../../../contexts/Channel/ChannelPublic/context";
import { ChannelPublicContextType } from "../../../contexts/Channel/ChannelPublic/type";
import {
  Accordion,
  Avatar,
  Button,
  Progress,
  Tooltip,
} from "../../../ludz.one_ui";
import LudzLoading from "../../../ludz.one_ui/Loading";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../styles/globalStyles";
import HeaderAccordion from "../../Zone/BuyZone/HeaderAccordion";
import { Container } from "../../style";
import Playlists from "./Playlists";
import * as C from "./style";
import ConfirmationDialog from "../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../contexts/PrivateRoutes/types";
import { Tag } from "primereact/tag";
import LudzScrollTop from "../../../ludz.one_ui/LudzScrollTop";

// import { Container } from './styles';

const BuyChannelScreen = () => {
  const { channelPublic, BuyChannel } = useContext(
    ChannelsPublicContext
  ) as ChannelPublicContextType;

  

  const props = useContext(PrivateRoutesContext) as PrivateRoutesContextType;

  const confirmBuyChannelPublic = () => {
    if (
      props.userBusinessChannel?.some(
        (item) => item?.id === channelPublic?.id
      ) ||
      props.userChannel?.some((item) => item.channel.id === channelPublic?.id)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [confirmBuy, setconfirmBuy] = useState(false);

  const matchesMobileSmall = useMediaQuery("(max-width: 520px)");
  const matchesTablet = useMediaQuery("(max-width: 920px)");
  const matches1024 = useMediaQuery("(max-width: 1024px)");

  let names = `${channelPublic?.name}`;
  var initialName = names.substring(0, 2);

  const NameRoomFormat = (name: any) => {
    if (name.length > 30) {
      return name.substring(0, 30) + "...";
    }
    return name;
  };

  // Verificar total de vídeos
  let totalVideos = 0;

  if (channelPublic?.playlists && Array.isArray(channelPublic?.playlists)) {
    channelPublic?.playlists.forEach((playlist) => {
      if (playlist.contents && Array.isArray(playlist.contents)) {
        totalVideos += playlist.contents.length;
      }
    });
  }
// Fim do código
  return (
    <Container
      style={{ padding:matches1024 ? '20px 4%':  matchesTablet? '20px 2%' :  matchesMobileSmall ? "20px 4% 4% 4%" : "20px 16%" }}
    >
      {channelPublic ? (
        <Column style={{ border: "1px solid #c3c3c3", borderRadius: "24px", backgroundColor: "rgba(127, 100, 170, 0.09)" }}>
          <C.CapaChannel background={channelPublic?.youtubeData?.jsonData![0]?.brandingSettings?.image?.bannerExternalUrl ? channelPublic?.youtubeData?.jsonData![0]?.brandingSettings?.image?.bannerExternalUrl  :channelPublic?.aws_2?.url}></C.CapaChannel>
          <Padding />
          <C.Container>
           <C.AvatarContainer>
           <Avatar
              shape="circle"
              size={matchesMobileSmall ? "large" : "xlarge"}
              label={!channelPublic?.aws?.url && !channelPublic?.youtubeData?.jsonData![0]?.snippet?.thumbnails?.medium?.url  ? initialName : undefined}
              img={
                channelPublic?.youtubeData?.jsonData![0]?.snippet?.thumbnails?.medium?.url ? channelPublic?.youtubeData?.jsonData![0]?.snippet?.thumbnails?.medium?.url :channelPublic?.aws?.url ? channelPublic?.aws?.url : undefined
              }
              style={{
                border: "1px solid #CCC",
                marginRight: "6px",
                width: matchesTablet ? '80px' : matchesMobileSmall ? "60px" : "120px",
                height: matchesTablet ? '80px' : matchesMobileSmall ? "60px" : "120px",
              }}
            />
           </C.AvatarContainer>
            <Row
              id="space-between"
              style={{ flexDirection: matchesMobileSmall ? "column" : "row" }}
            >
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Column>
                  <Tooltip title={channelPublic.name}>
                    <h1
                      className="edit-in-place"
                      dangerouslySetInnerHTML={{
                        __html:
                          NameRoomFormat(channelPublic.name) ||
                          "Escreva Alguma coisa",
                      }}
                    />
                  </Tooltip>
                  <C.AreaInfo>
                    {channelPublic.youtubeData ? 
                    <>
                     <span>{channelPublic?.youtubeData.jsonData.map(item => item.snippet.customUrl)}</span>•
                    <span>1 Playlist</span>
                    •
                    <span>{channelPublic.youtubeData.jsonDataDetails.length} vídeos</span>
                    </>
                    : 
                   <>
                    <span>#{channelPublic?.code}</span>•
                    <span>{channelPublic?.playlists?.length} Playlists</span>
                    •
                    <span>{totalVideos} vídeos</span>
                   </>
                    }
                  </C.AreaInfo>
                </Column>
              </Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                {/* <span className="text-900" style={{ fontWeight: 'bold' }}>{`R$ ${20.00}`}</span> */}
                <Padding padding="16px" />
                {confirmBuyChannelPublic() ? null : (
                  <Button
                    title="Obter canal"
                    onClick={() => {
                      setconfirmBuy(true);
                    }}
                  />
                )}

                <Padding padding="10px" />
              </div>
            </Row>
            <Padding padding="8px" />
            <div className="card mb-3">
              <h4 className="card-title mb-3">Descrição</h4>
              <p
                className="edit-in-place"
                dangerouslySetInnerHTML={{
                  __html: channelPublic?.description || "Escreva Alguma coisa",
                }}
              />
            </div>
            {channelPublic?.tags.length > 0 && (
              <div className="card mb-0">
                <h4 className="card-title mb-3">Tags</h4>
                <div className="flex justify-center items-center flex-wrap gap-2">
                  {channelPublic?.tags?.map((item: any, index: number) => (
                    <Tag
                      className="p-2"
                      rounded
                      style={{ background: "#E5E6EA", color: "black" }}
                    >
                      <span className="text-base">{item.name}</span>
                    </Tag>
                  ))}
                </div>
              </div>
            )}
            {channelPublic.tags.length > 0 && <Padding padding="8px" />}
            {channelPublic?.playlists ? (
              <div className="card">
                 <h4 className="mb-3">{`${channelPublic.youtubeData ? 'Videos' : 'Playlists'}`}</h4>
                {channelPublic?.youtubeData && 
                (
                  <div className="flex flex-column gap-2">
                    {channelPublic.youtubeData.jsonDataDetails.map(item => (
                      <div className="flex gap-2 align-items-center">
                        <img src={item.snippet.thumbnails.default.url} alt={item.snippet.title} style={{ borderRadius: '10px', height: '50px' }} />
                        <h4>{item.snippet.title}</h4>
                      </div>
                    ))}
                  </div>
                )
                }
                {channelPublic?.playlists.length !== 0 && (
                  <Accordion
                    body={Playlists}
                    header={HeaderAccordion}
                    data={channelPublic?.playlists}
                  />
                )}
              </div>
            ) : (
              <Progress />
            )}
          </C.Container>
        </Column>
      ) : (
        <LudzLoading />
      )}
      <ConfirmationDialog
        message="Deseja obter este canal?"
        visible={confirmBuy}
        onHide={() => {
          setconfirmBuy(false);
        }}
        reject={() => {
          setconfirmBuy(false);
        }}
        accept={() => BuyChannel(channelPublic?.id!, props.userProvider?.id!)}
      />
      <LudzScrollTop style={{ padding: '6px', right: '20px' }} />
    </Container>
  );
};

export default BuyChannelScreen;
