import { useContext, useState } from "react";
import { Column, Row } from "../../../styles/globalStyles";
import Input from "../../Inputs/InputText";
import { Padding } from "../../styles/styled-components";
import Modal from "../Modal/Modal";
import UserZone from "./UserZone";
import UserSubzone from "./UserSubzone";
import color from "../../styles/colors";
import { Button } from "../..";
import { ZonaContext } from "../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../contexts/Zone/Zone/type";
import { Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";


interface PropsModalInvite {
    visible: boolean,
    onOpen: any,
    users: any
}

const ModalInvite = ({ visible, onOpen, users }: PropsModalInvite) => {
    const { HandleInviteEmailMember, toastZone } = useContext(ZonaContext) as ZonaContextType
    const { id } = useParams()
    const [search, setSearch] = useState("")
    const [emailView, setEmailView] = useState(false)

    var user: any = [];


    if (search !== "") {
        const buscaLowerCase = search.toLowerCase();

        user = users.filter((users: any) =>
            users.user ? users.user.username.toLowerCase().includes(buscaLowerCase) : users.username.toLowerCase().includes(buscaLowerCase)
        );
    }

    return (
        <Modal title={"Adicionar Membro"} visible={visible} onOpen={onOpen}>
            <Toast ref={toastZone} />
            {!emailView ? <><Padding />
                <span className="p-input-icon-left" style={{ width: "100%" }}>
                    <i className="pi pi-search" />
                    <Input placeholder="Search" value={search} onChange={(e: any) => setSearch(e.target.value)} />
                </span>
                <Padding />
                {user.map((item: any, index: number) => {
                    return (
                        <div key={index}>{item.user ? <UserSubzone key={index} nome={item.user.username} id={item.user.id} avatar={item.user.aws.url} /> :
                            <UserZone key={index} nome={item.username} id={item.id} avatar={item.aws?.url} onOpen={onOpen} />
                        }</div>
                    )
                })}
                <>
                    {user.length === 0 && search.length === 0 ? <><Padding padding="16px" /><Row id="center"><h4>Digite um nome de usuário</h4></Row></> : null}
                    {user.length === 0 && search.length > 0 ? <><Padding padding="16px" /><Row id="center"><h4>Usuário não encontrado, convide por email, </h4> <h4 onClick={() => setEmailView(true)} style={{ color: color.primary, cursor: "pointer" }}>clique aqui</h4></Row></> : null}
                </></> : <>
                <Padding />
                <Formik initialValues={{ email: "", idZone: parseInt(id!) }} onSubmit={(values) => { HandleInviteEmailMember(values.email, values.idZone); setEmailView(false); setSearch(""); onOpen() }}>
                    {({ values, handleChange, }) => {
                        return (
                            <Form>

                                <Row>
                                    <span className="p-input-icon-left" style={{ width: "100%" }}>
                                        <i className="pi pi-envelope" />
                                        <Input name="email" placeholder="Search" value={values.email} onChange={handleChange} />
                                    </span>
                                    <Column id="center">
                                        <Button type="submit" icon="pi pi-send" />
                                    </Column>
                                    <Column id="center">
                                        <Button type="button" icon="pi pi-times" severity="danger" onClick={() => setEmailView(false)} />
                                    </Column>
                                </Row>
                            </Form>
                        )
                    }}
                </Formik>
                <Padding />
            </>}

        </Modal >
    )
}

export default ModalInvite;