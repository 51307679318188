import http from "../../../../../services/api";
import { logout } from "../../../../../services/localStorage";
import { PropsMetasCard } from "./type";
 
export const SubzoneMetasKanbanCardRequest = async (body: PropsMetasCard) => {
   try {
    const response = await http.post("/bff/kanban-card-objectives-bff", body);
    return response.data
   } catch (error: any) {
    if (error.response.status === 401) {
        logout()
        window.location.reload()
    }
    throw error;
   }
}

export const DeleteMetasCardRequest = async (id: number) => {

    try {
        const response = await http.delete(`/direct/kanban-card-objectives/${id}`);
        return response.data
    } catch (err: any) {
        if (err.response.status === 401) {
            logout()
            window.location.reload()
        }
        throw err;
    }
}

