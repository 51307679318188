import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { MemberKanbanCardController } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/MemberKanbanCard/controller"
import { KanbanCardSubzoneController } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/controller"
import { useFetchSubzoneKanbanCard } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/query"
import { PropsUpdateKanbanCard } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/type"
import { LabelsKanbanCarController } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/LabelsKanbanCard/controller"
import { PropsKanbanCardLabels } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/LabelsKanbanCard/type"
import { MetasKanbanCardController } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/MetasKanbanCard/controller"
import { PropsMetasCard } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/MetasKanbanCard/type"
import { CommetsKanbanCarController } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/CommentsKanbanCard/controller"
import { PropsKanbanCardComments } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/CommentsKanbanCard/type"
import { ArchivesKanbanCardController } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/ArchivesKanbanCard/controller"
import { ChecklistsKanbanCardController } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/ChecklistKanbanCard/controller"
import { PropsKanbanCardChecklist, PropsKanbanCardChecklistItems } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/ChecklistKanbanCard/type"
import { Toast } from "primereact/toast"


export const InsiderCardState = () => {

    const { idcard } = useParams()
    const [visibleMember, setvisibleMember] = useState(false)
    const [visibleTags, setvisibleTags] = useState(false)
    const [visibleMetas, setvisibleMetas] = useState(false)
    const [visibleArchives, setvisibleArchives] = useState(false)
    const [visibleChecklist, setvisibleChecklist] = useState(false)
    const [visibleDate, setvisibleDate] = useState(false);
    const [files, setFiles] = useState()

    const [card, setcard] = useState()

    const { data } = useFetchSubzoneKanbanCard(parseInt(idcard!))

    const toastKanbanCard = useRef<Toast>(null)
    
    const { UpdateKanbanCardSubzoneMutation } = KanbanCardSubzoneController({toast: toastKanbanCard})


    const handleUpdateCard = (body: PropsUpdateKanbanCard, id: number) => {
        UpdateKanbanCardSubzoneMutation.mutate({ body: body, id: id })
    }

    const toast = useRef<Toast>(null)

    // create and delete labels insider cards
    const { ArchivesKanbanCardMutation, DeleteArchivesKanbanCardMutation } = ArchivesKanbanCardController({ toast: toast })


    const handleArchives = (id: number) => {
        if (files) {

            var arq = files[0]

            ArchivesKanbanCardMutation.mutate({data: arq, id: id.toString()})
        }
    }

    const deleteArchives = (id: string) => {
        DeleteArchivesKanbanCardMutation.mutate(id)
    }


    // create and delete labels insider cards
    const { KanbanCardLabelsSubzoneMutation, DeleteKanbanCardLabelsSubzoneMutation } = LabelsKanbanCarController()
    const handleLabels = (body: PropsKanbanCardLabels) => {
       KanbanCardLabelsSubzoneMutation.mutate(body)
    }

    const deleteLabels = (id: number) => {
       DeleteKanbanCardLabelsSubzoneMutation.mutate(id)
    }


    // create and delete labels insider cards
    const { DeleteKanbanCardCommentsSubzoneMutation, KanbanCardCommentsSubzoneMutation } = CommetsKanbanCarController()

    const handleComments = (body: PropsKanbanCardComments) => {
        KanbanCardCommentsSubzoneMutation.mutate(body)
    }

    const deleteComments = (id: number) => {
        DeleteKanbanCardCommentsSubzoneMutation.mutate(id)
    }

    // create and delete member insider cards
    const { KanbanCardSubzoneMutation, DeleteKanbanMemberCardSubzoneMutation } = MemberKanbanCardController()

    const handleAddMember = (idMember: number, idCard: number) => {
        KanbanCardSubzoneMutation.mutate({ kanbanCard: idCard, member: idMember })
    }

    const deleteMemberCard = (id: number) => {
        DeleteKanbanMemberCardSubzoneMutation.mutate(id)
    }

    // create and delete metas insider cards
    const { DeleteKanbanMeteasCardSubzoneMutation, MetasKanbanCardSubzoneMutation } = MetasKanbanCardController()

    const handleMetas = (body: PropsMetasCard) => {
        MetasKanbanCardSubzoneMutation.mutate(body)
    }

    const deleteMetas = (id: number) => {
        DeleteKanbanMeteasCardSubzoneMutation.mutate(id)
    }

    // checklists insider cards
    const { KanbanCardChecklistsSubzoneMutation, UpdateKanbanCardChecklistsSubZoneMutation, DeleteKanbanCardChecklistsSubzoneMutation, KanbanCardChecklistItemsSubzoneMutation, UpdateKanbanCardChecklistItemsSubZoneMutation, DeleteKanbanCardChecklistItemsSubzoneMutation }  = ChecklistsKanbanCardController();

    const handleChecklist = (body: PropsKanbanCardChecklist) => {
        KanbanCardChecklistsSubzoneMutation.mutate(body)
    }

    const handleUpdateChecklists = (id: string, body: PropsKanbanCardChecklist) => {
        UpdateKanbanCardChecklistsSubZoneMutation.mutate({body, id})
    }

    const deleteChecklists = (id: string) => {
        DeleteKanbanCardChecklistsSubzoneMutation.mutate(id)
    }

    const handleChecklistItem = (body: PropsKanbanCardChecklistItems) => {
        KanbanCardChecklistItemsSubzoneMutation.mutate(body)
    }

    const handleUpdateChecklistItem = (id: string, body: PropsKanbanCardChecklistItems) => {
        UpdateKanbanCardChecklistItemsSubZoneMutation.mutate({body, id})
    }

    const deleteChecklistItems = (id: string) => {
        DeleteKanbanCardChecklistItemsSubzoneMutation.mutate(id)
    }

    // Date insider cards
    const handleUpdateDate = (body: PropsUpdateKanbanCard, id: number) => {
        UpdateKanbanCardSubzoneMutation.mutate({ body: body, id: id })
    }

    useEffect(() => {
        if (data) {
            setcard(data)
        }
    }, [data])






    return {
        card,
        setcard,
        handleUpdateCard,
        handleAddMember,
        deleteMemberCard,
        visibleMember,
        setvisibleMember,
        visibleTags,
        setvisibleTags,
        handleLabels,
        deleteLabels,
        visibleMetas,
        setvisibleMetas,
        handleMetas,
        deleteMetas,
        handleComments,
        deleteComments,
        visibleArchives,
        setvisibleArchives,
        setFiles,
        handleArchives,
        deleteArchives,
        handleChecklist,
        visibleChecklist, 
        setvisibleChecklist,
        handleUpdateChecklists,
        deleteChecklists,
        handleChecklistItem,
        handleUpdateChecklistItem,
        deleteChecklistItems,
        handleUpdateDate,
        visibleDate, 
        setvisibleDate,
        toast,
        toastKanbanCard,
    }
}