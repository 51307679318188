import React from 'react';
import { ScrollTop } from 'primereact/scrolltop';

// import { Container } from './styles';

interface Props {
    style?: React.CSSProperties,
}

const LudzScrollTop = ({ style }: Props) => {
  return (
    <ScrollTop style={{...style}} target="parent" threshold={100} className="w-2rem h-2rem border-round bg-primary opacity-60 hover:opacity-0" icon="pi pi-arrow-up text-base" />
    );
}

export default LudzScrollTop;