import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 380px;
  box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
  transition: all linear 0.5s;

  .icon-container {
    position: absolute;
    bottom: 0;
    width: 92%;
  }

  &:hover {
    transition: all ease-in-out 0.2s;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    #avatar {
      transition: all ease-in-out 0.1s;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
  }

  @media (min-width: 4160px) and (max-width: 5000px) {
    height: 680px;
    .icon-container {
      width: 96%;
    }
  }

  @media (min-width: 3480px) and (max-width: 4159px) {
    height: 600px;
    .icon-container {
      width: 95%;
    }
  }

  @media (min-width: 2560px) and (max-width: 3479px) {
    height: 455px;
    .icon-container {
      width: 95%;
    }
  }

  @media (min-width: 1920px) and (max-width: 2559px) {
    .icon-container {
      width: 94%;
    }
  }

  @media (max-width: 1024px) {
    height: 380px;
    .icon-container {
      width: 90%;
    }
  }

  @media (max-width: 915px) {
    height: 365px;
    .icon-container{
      width: 88%;
    }
  }

  @media (max-width: 821px) {
    height: 345px;
  }

  @media (max-width: 520px) {
    .icon-container {
      width: 88%;
    }
  }

  @media (max-width: 360px) {
    height: 370px;
  }
`;

export const Avatar = styled.img`
  border-radius: 50%;
  border: 1px solid #f8f9fa;
`;
