import React, { createContext } from "react";
import { PrivateRoutesContextType } from "./types";
import { PrivateRoutesStates } from "./state";

export const PrivateRoutesContext =
  createContext<PrivateRoutesContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const PrivateRoutesProvider = ({ children }: Props) => {
  const {
    userProvider,
    zonesProvider,
    userChannel,
    activeIndex,
    setActiveIndex,
    invites,
    notifications,
    UpdateNotifcation,
    UpdateAllNotification,
    userBusinessChannel,
    DeleteSelectionNotifications,
    input,
    messages,
    setInput,
    setMessages,
    toast,
    initialValues,
    schema,
    handleFormHelp,
    displayModalForm,
    setDisplayModalForm,
  } = PrivateRoutesStates();

  return (
    <PrivateRoutesContext.Provider
      value={{
        userChannel,
        userProvider,
        zonesProvider,
        activeIndex,
        setActiveIndex,
        invites,
        notifications,
        UpdateNotifcation,
        UpdateAllNotification,
        userBusinessChannel,
        DeleteSelectionNotifications,
        input,
        messages,
        setInput,
        setMessages,
        toast,
        initialValues,
        schema,
        handleFormHelp,
        displayModalForm,
        setDisplayModalForm,
      }}
    >
      {children}
    </PrivateRoutesContext.Provider>
  );
};

export default PrivateRoutesProvider;
