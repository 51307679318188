import { useMutation } from "react-query";
import queryClient from "../../../services/react-query";
import { useFetchSubzonePageObjectiveBff } from "./query";
import { DeleteSubzoneObjectiveRequest, SubzoneObjectiveRequest, UpdateChangeIndexObjectiveRequest, UpdateSubzoneObjectiveRequest } from "./request";
import { PropsSubzoneObjective } from "./type";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../components/Alert";

export const ObjectiveSubzonePageController = (id: number, {
  toast,
}: {
  toast?: RefObject<Toast>;
}) => {

    const {data: objectiveRequests, refetch} = useFetchSubzonePageObjectiveBff(id)

    const ObjectiveSubzoneMutation = useMutation((data: PropsSubzoneObjective) => SubzoneObjectiveRequest(data), {
        onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
           Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
          Alert({
            detail: "Objetivo criado!",
            severity: "success",
            summary: "Sucesso",
            toast: toast
          }).show();
         refetch()
        },
      });

      const ChangeIndexObjectiveSubzoneMutation = useMutation(({body, id}: {body: {newIndex: number}, id: string}) => UpdateChangeIndexObjectiveRequest(body, id), {
        onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
           Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
          Alert({
            detail: "Objetivo criado!",
            severity: "success",
            summary: "Sucesso",
            toast: toast
          }).show();
         refetch()
        },
      });

      

      const DeleteObjectiveSubzoneMutation = useMutation((id: number) => DeleteSubzoneObjectiveRequest(id), {
        onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
           Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
          Alert({
            detail: "Objetivo excluido!",
            severity: "success",
            summary: "Sucesso",
            toast: toast
          }).show();
         refetch()
        },
      });


      interface PropsUpdateObjetive {
        body: { name: string},
        id: string
      }
      const UpdateObjectiveSubzoneMutation = useMutation(({ body, id }: PropsUpdateObjetive) => UpdateSubzoneObjectiveRequest(body, id), {
        onMutate: () => {
          //   setLoading(true);
          //   queryClient.refetchQueries()
          queryClient.refetchQueries("UseSubzoneObjectiveBff");
    
        },
        onError: (error: any) => {
           Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
          console.log(error)
        },
        onSuccess: (result) => {
          Alert({
            detail: "Objetivo editado!",
            severity: "success",
            summary: "Sucesso",
            toast: toast
          }).show();
          queryClient.refetchQueries("UseSubzoneObjectiveBff");
    
        },
      });

    return{
        ObjectiveSubzoneMutation, objectiveRequests, DeleteObjectiveSubzoneMutation, UpdateObjectiveSubzoneMutation, ChangeIndexObjectiveSubzoneMutation
    }
}


