import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { Form, Formik } from "formik";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import { KanbanSubzonaContext } from "../../../../contexts/Subzone/KanbanSubzone/context";
import { KanbanSubzonaContextType } from "../../../../contexts/Subzone/KanbanSubzone/type";
import { Button, Icon, Input, OverlayPanelLudz } from "../../../../ludz.one_ui";
import ConfirmationDialog from "../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { PropsSubzoneKanbanCard } from "../../../../ludzoneSDK/Subzone/Kanban/KanbanCard/type";
import {
  Card,
  PropsSubzoneKanbanBFF,
} from "../../../../ludzoneSDK/Subzone/Kanban/type";
import { Column, Row } from "../../../../styles/globalStyles";
import { WrapCard } from "../AddList/style";
import InsiderOverlayPanel from "../KanbanList/Card/InsiderOverlayPanel";
import { ContainerList } from "../style";
import { Id } from "../types";
import ModalMoveColumn from "./MoveColumn";
import TaskCard from "./TaskCard";

interface Props {
  column: PropsSubzoneKanbanBFF;
  deleteColumn: (id: number) => void;
  updateColumn: (id: Id, title: string) => void;

  updateTask: (id: Id, content: string) => void;
  deleteTask: (id: Id) => void;
  tasks?: Card[];
}

function ColumnContainer({
  column,
  deleteColumn,
  updateColumn,
  tasks,
  deleteTask,
  updateTask,
}: Props) {
  const [editName, seteditName] = useState(false);
  const [title, setTitle] = useState(column.title);
  const [visible, setVisible] = useState(false);
  const [open, setopen] = useState(false);
  const op = useRef<OverlayPanel>(null);
  const [visibleMove, setVisibleMove] = useState(false);
  const handlePanelContentClick = () => {
    if (op.current) {
      op.current.hide();
    }
  };

  const { handleKanbanCard, initialValueCard, schemaCard, handleScroll } = useContext(
    KanbanSubzonaContext
  ) as KanbanSubzonaContextType;


  const tasksIds = useMemo(() => {
    return tasks?.map((task) => task.id);
  }, [tasks]);

  const scrollContainerRef = useRef<any>(null);

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef?.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 10);
    }
  };


  const {
    setNodeRef,
    attributes,
    listeners
  } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    },
  });

  return (
    <ContainerList ref={setNodeRef}>
      <div {...attributes} {...listeners}>
        <Row>
          {editName ? (
            <Row style={{ width: "100%" }} id="space-between">
              <Input
                onChange={(e: any) => setTitle(e.target.value)}
                placeholder="Escreva um nome"
                value={title}
                onBlur={(e: any) => updateColumn(column.id, e.target.value)}
              />{" "}
              <div
                style={{
                  justifyContent: "center",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <Icon
                  icon="pi pi-times"
                  size="1rem"
                  style={{ cursor: "pointer" }}
                  onClick={() => seteditName(false)}
                />
              </div>
            </Row>
          ) : (
            <Row id="space-between" style={{ width: "100%" }}>
              <header
                onClick={() => seteditName(true)}
                style={{ fontFamily: "var(--font-familyS)" }}
              >
                {column.title}
              </header>
            </Row>
          )}
          <Column id="center">
            <OverlayPanelLudz
              ref={op}
              styleButton={{
                background: "#e4e6e7",
                height: "24px",
                width: "24px",
                borderRadius: "50%",
                display: "flex",
              }}
              button={
                <Icon
                  icon="pi pi-ellipsis-v"
                  size="1rem"
                  style={{ margin: "auto", cursor: "pointer" }}
                />
              }
              components={
                <InsiderOverlayPanel
                  item={[
                    {
                      icon: "pi pi-arrow-right",
                      name: "Mover",
                      onClick: () => {
                        setVisibleMove(true);
                        handlePanelContentClick();
                      },
                    },
                    {
                      icon: "pi pi-pencil",
                      name: "Editar",
                      onClick: () => {
                        seteditName(true);
                        handlePanelContentClick();
                      },
                    },
                    {
                      icon: "pi pi-trash",
                      name: "Excluir",
                      onClick: () => {
                        setVisible(true);
                        handlePanelContentClick();
                      },
                    },
                  ]}
                />
              }
            />
          </Column>
        </Row>
      </div>

      {/* Column task container */}
      <ul ref={scrollContainerRef} onScroll={(e) => handleScroll(e, column.id)}>
        <SortableContext items={tasksIds!} >


          {tasks?.map((task) => (
            <TaskCard
              key={task.id}
              task={task}
              deleteTask={deleteTask}
              updateTask={updateTask}
            />
          ))}

        </SortableContext>
          {open && (
            <CreateCard column={column} handleKanbanCard={handleKanbanCard} initialValueCard={initialValueCard} schemaCard={schemaCard} setopen={setopen} open={open} />
          )}
      </ul>
      {/* Column footer */}
      {!open && (
        <Button
          style={{ width: "100%" }}
          onClick={(e: any) => {
            setopen(!open);
            scrollToBottom();
            handleScroll(e, column.id, true)
          }}
          icon={"pi pi-plus"}
          iconPos="left"
          title="Novo Card"
        />
      )}
      <ConfirmationDialog
        accept={() => deleteColumn!(column.id)}
        visible={visible}
        reject={() => setVisible(true)}
        message="Todos os cartões serão excluídos. Deseja confirmar a exclusão?"
        onHide={() => setVisible(false)}
      />
      <ModalMoveColumn
        setvisible={setVisibleMove}
        visible={visibleMove}
        id={column.id}
      />
    </ContainerList>
  );
}


const CreateCard = ({ handleKanbanCard, initialValueCard, schemaCard, setopen, open, column }: {
  schemaCard: any,
  initialValueCard: {
    title: string;
  };
  handleKanbanCard: (data: PropsSubzoneKanbanCard, id: number, index: number) => void
  setopen: (value: SetStateAction<boolean>) => void
  open?: boolean
  column: PropsSubzoneKanbanBFF
}

) => {

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [open]);

  return (
    <>
      <WrapCard>
        <Padding padding="8px">
          <Formik
            validationSchema={schemaCard}
            initialValues={initialValueCard}
            onSubmit={(values: any) => {
              handleKanbanCard(values, column.id, column.index);
              setopen(!open);
            }}
          >
            {({
              values,
              handleChange,
              errors,
              touched,
              handleSubmit,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <InputText
                    ref={inputRef}
                    style={{ width: "100%" }}
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    placeholder="Insira o título ao card..."
                  />
                  <Padding padding="8px" />
                  <Row id="space-between">
                    <Button type="submit" title="Criar card" />
                    <Column
                      style={{ cursor: "pointer" }}
                      onClick={() => setopen(!open)}
                      id="center"
                    >
                      <Icon icon="pi pi-times" />
                    </Column>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Padding>
      </WrapCard>
    </>
  )
}


export default ColumnContainer;
