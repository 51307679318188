import { memo, useContext, useMemo, useState, useEffect, useCallback } from "react";
import { KanbanCardSubzonaContext } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import CheckboxLudz from "../../../../../../../../../ludz.one_ui/Checkboxs";
import { Column, Row } from "../../../../../../../../../styles/globalStyles";
import { ButtonTags } from "../../../style";
import { CheckboxChangeEvent } from "primereact/checkbox";

interface PropsMetas {
    item: any,
}

const Metas = memo(({ item }: PropsMetas) => {
    const [pendingUpdates, setPendingUpdates] = useState(false);
    const { card, deleteMetas, handleMetas } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType;
    const [cardmetas, setCardMetas] = useState(card);

    // Sincronizar cardmetas com card sempre que o contexto for atualizado
    useEffect(() => {
        setCardMetas(card);
    }, [card]);

    const isCheck = useMemo(() => cardmetas?.objectives?.find((i: any) => i?.objective_key?.id === item?.id), [cardmetas?.objectives, item?.id]);

    const handleCheckChange = useCallback((event: CheckboxChangeEvent) => {
        const newChecked = event.target.checked;
        const prevChecks = cardmetas?.objectives ? [...cardmetas.objectives] : []; // Verificação defensiva

        setPendingUpdates(true);

        if (newChecked) {
            const updatedChecks = [...prevChecks, { objectives: item }];
            setCardMetas((prevCard: any) => ({ ...prevCard, objectives: updatedChecks }));

            try {
                handleMetas({ objective: item?.objective_fk, objective_key: item?.id, kanbanCard: cardmetas?.id });
            } catch (error) {
                // Reverte a mudança caso ocorra um erro
                setCardMetas((prevCard: any) => ({ ...prevCard, objectives: prevChecks }));
            } finally {
                setPendingUpdates(false);
            }
        } else {
            // Remover meta
            const updatedChecks = prevChecks.filter((i: any) => i.objective_key.id !== item.id);
            setCardMetas((prevCard: any) => ({ ...prevCard, objectives: updatedChecks }));

            try {
                deleteMetas(isCheck?.id);
            } catch (error) {
                // Reverte a mudança caso ocorra um erro
                setCardMetas((prevCard: any) => ({ ...prevCard, objectives: prevChecks }));
            } finally {
                setPendingUpdates(false);
            }
        }
    }, [cardmetas?.id, cardmetas.objectives, deleteMetas, handleMetas, isCheck?.id, item]);

    return (
        <Row>
            <Column id="center">
                {pendingUpdates ? (
                    <i className="pi pi-spinner" />
                ) : (
                    <CheckboxLudz checked={!!isCheck} onChange={handleCheckChange} />
                )}
            </Column>
            <Column style={{ width: "100%" }}>
                <ButtonTags style={{ backgroundColor: `${item.color}` }}>
                    <h5>{item.content}</h5>
                </ButtonTags>
            </Column>
        </Row>
    );
});

export default Metas;
