import http from "../../../services/api"
import { logout } from "../../../services/localStorage";
import { PropsSubzoneObjective } from "./type"

export const SubzoneObjectiveRequest = async (body: PropsSubzoneObjective) => {
    return await http.post("/direct/sub-zone-objective", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const DeleteSubzoneObjectiveRequest = async (id: number) => {
    return await http.delete(`/direct/sub-zone-objective/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const GetSubzoneObjectiveRequest = async (id: number) => {
    return await http.get("/direct/sub-zone-objective", {
        params: {
            include: JSON.stringify({
                objectiveKeys: true
            }),
            subZone_fk: id,
        }
    }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            throw err;
        });
} 

export const UpdateSubzoneObjectiveRequest = async (body: any, id: string) => {
    return await http.put(`/direct/sub-zone-objective/${id}`, body).then((response) => response.data)
   .catch (err => {
    if (err.response.status === 401) {
         logout()
         window.location.reload()
    }
    alert(err.response.data.message)
    throw err;
});
}

export const UpdateChangeIndexObjectiveRequest = async (body: {newIndex: number}, id: string) => {
    return await http.put(`/direct/sub-zone-objective/change-index/${id}`, body).then((response) => response.data)
   .catch (err => {
    if (err.response.status === 401) {
         logout()
         window.location.reload()
    }
    alert(err.response.data.message)
    throw err;
});
}



export const GetPageObjectiveBffRequest = async (id: number) => {
    return await http.get("/bff/objective-bff", {
        params: {
            subZoneId: id,
        }
    }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            throw err;
        });
} 
