import React from "react";
import { MeterBar, MeterContainer, MeterFill, MeterLabel, PointLabel } from "./styles";

interface MeterProps { label: string; value: number; color?: string; }

const MeterGroup = ({ label, value, color }: MeterProps) => {
  return (
    <MeterContainer>
      
      <MeterBar>
        <MeterFill value={value} color={color}></MeterFill>
      </MeterBar>
      <MeterLabel> <PointLabel color={color}>•</PointLabel> {label} {`(${value.toFixed(2)}%)`}</MeterLabel>
    </MeterContainer>
  );
};

export default MeterGroup;
