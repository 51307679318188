import { Form, Formik } from "formik";
import { useContext } from "react";
import { ObjectiveSubzonaContext } from "../../../../../contexts/Subzone/ObjectiveSubzone/context";
import { ObjectiveSubzonaContextType } from "../../../../../contexts/Subzone/ObjectiveSubzone/type";
import { Dropdown, Input, Modal } from "../../../../../ludz.one_ui";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../styles/globalStyles";
import { Button } from "primereact/button";

interface PropsModalEditObjetive {
    item: any,
    visible: boolean,
    onOpen: any
}

const ModalEditObjetive = ({ item, onOpen, visible }: PropsModalEditObjetive) => {
    const { handleUpdateObjective, schema, objective, handleChangeIndex } = useContext(ObjectiveSubzonaContext) as ObjectiveSubzonaContextType

    const listSesstion = objective?.map((objective: any, index: number) => {
        return { name: item?.index === index ? `${index + 1 + " - " + objective.name! + " (Atual)"}` : `${index + 1 + " - " + objective.name!} `, index: index }
    })

    return (
        <Modal title={"Editar objetivo"} visible={visible} onOpen={onOpen}>

            <div onClick={(e) => { e.stopPropagation(); }}>
                {objective && <Formik initialValues={{ name: item?.name || '', index: listSesstion?.find((props: any) => props.index === item?.index)  }} validationSchema={schema} onSubmit={(values) => {
                    handleUpdateObjective({ name: values.name }, item.id)
                    if (item.index !== values.index?.index) {
                        handleChangeIndex({ newIndex: values.index?.index! }, item?.id)
                    }
                    onOpen()
                }}>
                    {({ values, handleChange, errors, touched, handleSubmit }) => {
                        return (





                            <Form onSubmit={handleSubmit}>
                                <Column >
                                    <Input name="name" value={values.name} onChange={handleChange}
                                        onKeyDown={(e: any) => {
                                            if (e.key === ' ') {
                                                e.stopPropagation();
                                            }
                                        }}
                                        label="Nome do Objetivo" />
                                </Column>
                                <Padding padding="16px" />
                                {errors.name && touched.name ? (
                                    <div style={{ color: "red" }}>{errors.name.toString()}</div>
                                ) : null}
                                <Padding padding="8px" />

                                <label>
                                    Posição do conteúdo
                                </label>
                                <Padding />
                                <Dropdown options={listSesstion} name="index" value={values.index} onChange={handleChange} optionLabel="name" />
                                <Padding padding="32px" />
                                <Row id="center">
                                    <Button title="Salvar" type="submit">Salvar</Button>
                                </Row>
                            </Form>





                        )
                    }}
                </Formik>}
            </div>

        </Modal>
    )
}

export default ModalEditObjetive;