import { useContext } from "react";
import MenuPrime from "../../../ludz.one_ui/Menu/MenuPrime";
import { getId, logout } from "../../../services/localStorage";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../contexts/PrivateRoutes/types";
import { useNavigate } from "react-router-dom";
import { Avatar } from "../../../ludz.one_ui";

interface PropsUser {
  userProvider: any
}

const User = ({userProvider}: PropsUser) => {
  const { setActiveIndex } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  const history = useNavigate();

  let items = [
    {
      template: () => {
        return (
          <div style={{ display: 'flex', alignItems: "center", padding: '16px', wordBreak: 'break-all' }}>
            <Avatar
              style={{ marginRight: '8px' }}
              shape="circle"
              label={
                !userProvider?.url
                  ? userProvider?.name.substring(0, 1)
                  : undefined
              }
              size="normal"
              img={userProvider?.url ? userProvider?.url : undefined}
            />
            <div className="flex flex-column align" >
              <span style={{ wordBreak: 'break-word' }} className="font-bold">{userProvider?.username}</span>
              <span style={{ wordBreak: 'break-word' }} className="text-sm">{userProvider?.email}</span>
              <p style={{ wordBreak: 'break-word' }} className="text-sm font-semibold text-green-600">{userProvider?.plan?.name}</p>
            </div>
          </div>
        );
      },
    },
    { separator: true},
    {
      label: "Perfil",
      command: () => {
        history(`/perfil/${getId()}`);
        setActiveIndex(0);
      },
      icon: "pi pi-user-edit",
    },
    {
      label: "Notificações",
      command: () => {
        history(`/perfil/${getId()}`);
        setActiveIndex(1);
      },
      icon: "pi pi-bell",
    },
    {
      label: "Convites",
      command: () => {
        history(`/perfil/${getId()}`);
        setActiveIndex(2);
      },
      icon: "pi pi-user-plus",
    },
    {
      label: "Configurações",
      command: () => {
        history(`/perfil/${getId()}`);
        setActiveIndex(3);
      },
      icon: "pi pi-cog",
    },
    {
      label: "Sair",
      command: () => {
        logout();
        window.location.reload();
      },
      icon: "pi pi-sign-out",
    },
  ];
  return <MenuPrime items={items} width="250px" />;
};

export default User;
