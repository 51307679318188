import { Grid } from "@mui/material"
import { useContext } from "react"
import { KanbanCardSubzonaContext } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context"
import { KanbanCardSubzonaContextType } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type"
import { Icon, Tooltip } from "../../../../../../../ludz.one_ui"
import { Padding } from "../../../../../../../ludz.one_ui/styles/styled-components"
import { AddButton, Label } from "../style"

const TagsCard = () => {

    const { visibleTags, setvisibleTags, card } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType

    function getContrastColor(bgColor: string) {
        if (!bgColor) { return ''; }
        const rgb = parseInt(bgColor.replace('#', ''), 16);   // convert rrggbb to decimal
        const r = (rgb >> 16) & 0xff;  // extract red
        const g = (rgb >>  8) & 0xff;  // extract green
        const b = (rgb >>  0) & 0xff;  // extract blue
    
        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    
        return luma < 128 ? 'white' : '#363636';
    }
    
    // No seu componente React
    let bgColor: string; // A cor de fundo que você quer verificar
    let textColor: any;

    return (
        <div>
            <h4>Tags</h4>
            <Padding />
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "8px 8px",
                }}
            >
                <Grid container spacing={1}>
                    {card?.labels.map((item: any, index: number) => {
                        bgColor = item?.label.color
                        textColor =  getContrastColor(bgColor);
                        return (
                            <Grid item >
                                <Tooltip key={index} title={item?.label.name}>
                                    <Label key={index} color={item?.label.color}>
                                        <h4 style={{ margin: "auto", color: `${textColor}` }}>
                                            {item?.label.name.length > 10 ? item?.label.name?.substring(0, 10) + '...' : item?.label.name?.substring(0, 10) }
                                        </h4>
                                    </Label>
                                </Tooltip>
                            </Grid>
                        );
                    })}
                    <Grid item>
                        <AddButton onClick={() => setvisibleTags(!visibleTags)}>
                            <Icon size="1rem" icon="pi pi-plus" style={{ margin: "auto" }} />
                        </AddButton>
                    </Grid>
                </Grid>
            </div>
            
        </div>
    )
}

export default TagsCard