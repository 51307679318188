import http from "../../../services/api"
import { logout } from "../../../services/localStorage";
import { PropsChangeIndexSession, PropsSession } from "./type"

export const SessionZoneRequest = async (body: PropsSession) => {
    return await http.post("/direct/session", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err)
            throw err;
        });
}

export const ChangeIndexSessionZoneRequest = async (body: PropsChangeIndexSession) => {
    return await http.put("/bff/session-bff/change-index/" + body.sessionId, { newIndex: body.newIndex }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err)
            throw err;
        });
}


export const EditTextSessionZoneRequest = async (body: { name: string }, id: string) => {
    return await http.put(`/direct/session/${id}`, body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err)
            throw err;
        });
}


export const BffGetZoneSessionsRequest = async (id: number) => {
    return await http.get("/bff/session-bff", {
        params: {
            zoneId: id,
        }
    }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            throw err;
        });
}

export const DeleteSessionZoneRequest = async (id: string) => {
    return await http.delete(`/direct/session/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err)
            throw err;
        });
}