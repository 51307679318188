import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputSwitchChangeEvent } from "primereact/inputswitch";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { Toast } from "primereact/toast";
import { Dispatch, SetStateAction, useContext, useRef } from "react";
import { KanbanSubzonaContext } from "../../../../contexts/Subzone/KanbanSubzone/context";
import { KanbanSubzonaContextType } from "../../../../contexts/Subzone/KanbanSubzone/type";
import { ObjectiveSubzonaContext } from "../../../../contexts/Subzone/ObjectiveSubzone/context";
import { ObjectiveSubzonaContextType } from "../../../../contexts/Subzone/ObjectiveSubzone/type";
import { SubzoneState } from "../../../../contexts/Subzone/Subzone/state";
import { Input, Tooltip } from "../../../../ludz.one_ui";
import LudzDropdown from "../../../../ludz.one_ui/Dropdown/Dropdown";
import LudzInputSwitch from "../../../../ludz.one_ui/Inputs/InputSwitch";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import * as C from "./style";
import { Calendar } from "primereact/calendar";

interface PropsModal {
  kanbancard: any;
  search: any;
  setSearch: any;
  selectedMetas: any;
  setSelectedMetas: any;
  selectedObjetivos: any;
  setSelectedObjetivos: any;
  membros: any;
  setMembros: any;
  dates: any;
  setDates: any;
  status: boolean;
  setStatus: Dispatch<SetStateAction<boolean>>;
  checked: boolean;
  setChecked: any;
  selectedTags: any;
  setSelectedTags: any;
}

const ModalFilter = ({
  kanbancard,
  search,
  setSearch,
  selectedObjetivos,
  setSelectedObjetivos,
  selectedMetas,
  setSelectedMetas,
  membros,
  setMembros,
  dates,
  setDates,
  status,
  setStatus,
  checked,
  setChecked,
  selectedTags,
  setSelectedTags,
}: PropsModal) => {
  const menuLeft: any = useRef(null);
  const toast: any = useRef<Toast>(null);

  const { subzone } = SubzoneState();

  const { kanbanTags } = useContext(
    KanbanSubzonaContext
  ) as KanbanSubzonaContextType;

  const { objective } = useContext(
    ObjectiveSubzonaContext
  ) as ObjectiveSubzonaContextType;

  let objetivos: any = objective;

  let allobjetivos = { name: "Sem Filtro", id: "ALL" };

  if (Array.isArray(objetivos)) {
    objetivos = [allobjetivos, ...objetivos];
  }

  let metas = objective?.map((obj: any) => obj?.objectiveKeys).flat();

  let allmetas = { content: "Sem Filtro", id: "ALL" };

  if (Array.isArray(metas)) {
    metas = [allmetas, ...metas];
  }

  let membroskanban = subzone?.members.map((props: any) => props.user).flat();

  let allmembros = { username: "Sem Filtro", id: "ALL" };

  if (Array.isArray(membroskanban)) {
    membroskanban = [allmembros, ...membroskanban];
  }

  let tags = kanbanTags?.map((obj: any) => obj).flat();

  let alltags = { name: "Sem Filtro", id: "ALL" };

  if (Array.isArray(tags)) {
    tags = [alltags, ...tags];
  }

  const habilitebutton =
    search !== "" ||
    dates !== null ||
    membros !== "" ||
    selectedTags !== "" ||
    selectedMetas !== "" ||
    selectedObjetivos !== "" ||
    status === true
      ? false
      : true;

  const handleBlockClick = (event: any) => {
    event.stopPropagation();
  };

  const items: MenuItem[] = [
    {
      template: (item, options) => {
        return (
          <Padding padding="8px">
            <h4 style={{ textAlign: "center" }}>
              Escolha uma opção de filtragem
            </h4>
          </Padding>
        );
      },
    },
    {
      template: (item, options) => {
        //status de finalizado ou não finalizado
        return (
          <Padding padding="4px 8px" onClick={handleBlockClick}>
            <div
              className="flex align-items-center"
              style={{ marginBottom: "8px" }}
            >
              <Checkbox
                checked={status}
                onChange={(e: any) => setStatus(e.checked)}
                inputId="status"
                name="status"
              />
              <label htmlFor="status" className="ml-2">
                habilitar status
              </label>
            </div>
            {status && (
              <LudzInputSwitch
                checked={checked}
                onChange={(e: InputSwitchChangeEvent) => setChecked(e.value)}
                label="Finalizado/Não Finalizado"
              />
            )}
          </Padding>
        );
      },
    },
    {
      template: (item, options) => {
        // filtro de objetivos
        return (
          <Padding padding="6px 8px" onClick={handleBlockClick}>
            <LudzDropdown
              label="Objetivos"
              value={selectedObjetivos}
              onChange={(e: any) => setSelectedObjetivos(e.value)}
              options={objetivos}
              optionLabel="name"
              placeholder="Objetivos"
              style={{
                fontStyle:
                  selectedObjetivos?.name === "Todos objetivos"
                    ? "italic"
                    : "normal",
              }}
            />
          </Padding>
        );
      },
    },
    {
      template: (item, options) => {
        // filtro de metas
        return (
          <Padding padding="0px 8px" onClick={handleBlockClick}>
            <LudzDropdown
              label="Metas"
              value={selectedMetas}
              onChange={(e: any) => setSelectedMetas(e.value)}
              options={metas}
              optionLabel="content"
              style={{
                fontStyle:
                  selectedMetas?.content === "Todas metas"
                    ? "italic"
                    : "normal",
              }}
              placeholder="Metas"
            />
          </Padding>
        );
      },
    },
    {
      template: (item, options) => {
        // filtro de membros
        return (
          <Padding padding="0px 8px" onClick={handleBlockClick}>
            <LudzDropdown
              label="Membros"
              value={membros}
              onChange={(e: any) => setMembros(e.value)}
              options={membroskanban}
              optionLabel="username"
              style={{
                fontStyle:
                  membros?.username === "Todos membros" ? "italic" : "normal",
              }}
              placeholder="Membros"
            />
          </Padding>
        );
      },
    },
    {
      template: (item, options) => {
        // filtros de tags
        return (
          <Padding
            padding="0px 8px"
            onClick={handleBlockClick}
            style={{ marginBottom: "12px" }}
          >
            <LudzDropdown
              value={selectedTags}
              label="Tags"
              onChange={(e: any) => setSelectedTags(e.value)}
              options={tags}
              optionLabel="name"
              style={{
                fontStyle:
                  selectedTags?.name === "Todas tags" ? "italic" : "normal",
              }}
              placeholder="Tags"
            />
          </Padding>
        );
      },
    },

    { separator: true },
    {
      template: (item, options) => {
        // filtro de datas
        return (
          <Padding
            padding="0px 8px"
            onClick={handleBlockClick}
            style={{ margin: "12px 0px 8px 0px" }}
          >
              <label htmlFor="periododata" className="block mb-2">
                    Data de entrega
                </label>
            <Calendar id="periododata" style={{ width: '100%' }} dateFormat="dd/mm/yy" value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput showIcon placeholder="informe o período de datas"  />
          </Padding>
        );
      },
    },
    {
      template: (item, options) => {
        // filtro de nome do card
        return (
          <Padding padding="0px 8px">
            <Input
              label="Titulo Card"
              value={search}
              placeholder="Digite o titulo do Card"
              onChange={(e: any) => setSearch(e.target.value)}
            />
          </Padding>
        );
      },
    },
    {
      template: (item, options) => {
        // botão de ação de limpar
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Tooltip title="limpar filtragem">
              <Button
                size="small"
                label="Limpar"
                rounded
                outlined
                style={{ marginTop: "8px" }}
                onClick={() => {
                  setDates(null);
                  setMembros("");
                  setSearch("");
                  setSelectedObjetivos("");
                  setSelectedMetas("");
                  setStatus(false);
                  setSelectedTags("");
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <C.Container>
      <Toast ref={toast}></Toast>
      <Menu
        model={items}
        popup
        ref={menuLeft}
        id="popup_menu_left"
        style={{ width: "280px", padding: "8px", borderRadius: "16px" }}
      />
      <Tooltip title="filtro do kanbancard">
        <Button
          icon="pi pi-filter"
          rounded
          outlined={habilitebutton}
          className="mr-2"
          onClick={(event) => menuLeft.current.toggle(event)}
          aria-controls="popup_menu_right"
          aria-haspopup
        />
      </Tooltip>
    </C.Container>
  );
};

export default ModalFilter;
