import Lottie from "lottie-react";
import LudzIAIcon from "../../../assets/LudzIA.json";
import ReactMarkdown from "react-markdown";
import { ChatMessageType } from "./types";
import { Avatar, Tooltip } from "../../../ludz.one_ui";
import { IAMessage, Message, UserMessage } from "../IAstyles";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialOceanic } from "react-syntax-highlighter/dist/esm/styles/prism";

interface ChatMessageProps {
  message: ChatMessageType;
  index: number;
  userImage: string | undefined;
  handleCopy: (text: string) => void;
  handleShare: (text: string) => void;
}

const ChatMessage = ({
  message,
  index,
  userImage,
  handleCopy,
  handleShare,
}: ChatMessageProps) => {
  const formatMessageContentCopy = (content: string) => {
    // Substituir ### por ponto final
    let formattedContent = content.replace(/###/g, "•");

    // Remover a formatação de **texto** para copiar sem HTML
    formattedContent = formattedContent.replace(/\*\*(.*?)\*\*/g, "$1");

    // Remover a formatação de `código` inline para copiar sem HTML
    formattedContent = formattedContent.replace(/`([^`]+)`/g, "$1");

    // Remover a formatação de ```código``` para copiar sem HTML
    formattedContent = formattedContent.replace(/```([\s\S]*?)```/g, "$1");

    return formattedContent;
  };

  const checkMessage = () => {
    let text = message.content;

    if (
      text ===
      "Preciso que me ajude a organizar minha agenda de hoje. Pergunte quais são as minhas principais tarefas e compromissos que preciso concluir hoje e me ajude a organizar minha agenda de forma eficiente."
    ) {
      return "Organização de Agenda.";
    } else if (text === "Preciso que resuma um texto para mim.") {
      return "Resumo de Texto.";
    } else if (
      text ===
      "Quero melhorar minha produtividade e preciso de orientações. Primeiro, pergunte sobre minha atividade ou trabalho, depois sobre os maiores desafios que enfrento, e por fim, sobre o objetivo que desejo alcançar. Com essas informações, você pode me fornecer dicas e estratégias para melhora"
    ) {
      return "Dicas para Melhorar a Produtividade.";
    } else if (
      text ===
      "Preciso que escreva um email, mas primeiro pergunte para quem se destina e do que se trata."
    ) {
      return "Escreva um E-mails";
    } else if (
      text ===
      "Me ajude a estruturar uma apresentação, mas primeiro pergunte o tema e o público-alvo."
    ) {
      return "Estruturação de Apresentação.";
    } else if (
      text ===
      "Sugira atividades interativas para minha zona, mas pergunte primeiro do que se trata a zona."
    ) {
      return "Atividades Interativas para Zona.";
    } else if (
      text ===
      "Crie três exercícios de múltipla escolha, mas primeiro pergunte o tema desejado. Em seguida, apresente cada questão uma de cada vez, permitindo que o usuário responda e, logo após, informe se a resposta está correta ou incorreta. Ao final, pergunte ao usuário se ele gostaria de revisar os exercícios."
    ) {
      return "Crie Exercício de Múltipla Escolha.";
    } else if (
      text === "Preciso que explique em termos simples sobre um tema."
    ) {
      return "Revisão de Conteúdo.";
    } else if (
      text ===
      "Me ajude a estruturar uma redação, mas primeiro pergunte o tema."
    ) {
      return "Redação de Trabalhos.";
    } else if (
      text ===
      "Organizar uma pesquisa acadêmica utilizando as funcionalidades da plataforma Ludzone."
    ) {
      return "Organize sua pesquisa acadêmica.";
    } else if (
      text ===
      "Gostaria de melhorar minha qualidade de vida utilizando o Ludzone como ferramenta."
    ) {
      return "Gerencie sua qualidade de vida.";
    } else if (
      text ===
      "Organizar um curso online na plataforma Ludzone, incluindo a criação de uma Zona para o curso, módulos com conteúdo programático, Subzonas para trabalhos em grupo, definição de metas e tarefas, e utilização do quadro de tarefas para gerenciamento das atividades dos grupos."
    ) {
      return "Crie um Curso Online";
    } else if (
      text ===
      "Como uso o Ludzone para uma consultoria empresarial junto a meus clientes."
    ) {
      return "Gerenciar uma consultoria Empresarial";
    } else if (
      text ===
      "Como posso usar o Ludzone afim de desenvolver ideias criativas para projetos."
    ) {
      return "Desenvolva ideias criativas para projetos.";
    } else if (
      text ===
      "Como faço para utilizar o Ludzone de maneira que Facilite dinâmicas de grupo e workshops."
    ) {
      return "Facilite dinâmicas de grupo e workshops.";
    }

    return text;
  };

  return (
    <Message key={index} sender={message.role}>
      {message.role === "user" ? (
        <UserMessage lang="pt-br">
          <div className="flex align-items-center align-self-end mb-2">
            <h5>Você</h5>
            <Avatar
              shape="circle"
              img={userImage}
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                marginLeft: "8px",
                cursor: "pointer",
                alignSelf: "flex-end",
                flexShrink: "0",
              }}
            />
          </div>
          <p>{checkMessage()}</p>
        </UserMessage>
      ) : message.role === "assistant" ? (
        <IAMessage lang="pt-br">
          <div className="flex align-items-center align-self-start mb-2">
            <Lottie
              style={{
                marginRight: "8px",
                width: "30px",
                height: "30px",
                alignSelf: "flex-start",
              }}
              className="lottie-avatar"
              animationData={LudzIAIcon}
            />
            <h5>Ludz IA</h5>
          </div>
          <div className="markdown-content">
            <ReactMarkdown
              components={{
                code({ node, inline, className, children, ...props }: any) {
                  const match = /language-(\w+)/.exec(className || "");
                  return !inline && match ? (
                    <SyntaxHighlighter
                      style={materialOceanic}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    >
                      {String(children).replace(/\n$/, "")}
                    </SyntaxHighlighter>
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            >
              {message.content}
            </ReactMarkdown>
          </div>

          <div
            className="flex align-self-start mt-2 gap-2"
            style={{ color: "#333333" }}
          >
            <Tooltip title="copiar">
              <i
                onClick={() =>
                  handleCopy(formatMessageContentCopy(message.content))
                }
                className="pi pi-copy cursor-pointer"
              />
            </Tooltip>
            <Tooltip title="compartilhar">
              <i
                onClick={() =>
                  handleShare(formatMessageContentCopy(message.content))
                }
                className="pi pi-share-alt cursor-pointer"
              />
            </Tooltip>
          </div>
        </IAMessage>
      ) : null}
    </Message>
  );
};

export default ChatMessage;
