import React, { useContext, useState } from "react";
import { Button, InputTextArea, Modal } from "../../../ludz.one_ui";
import { Formik } from "formik";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../contexts/PrivateRoutes/types";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Row } from "../../../styles/globalStyles";
import CheckboxLudz from "../../../ludz.one_ui/Checkboxs";
import LudzInputSwitch from "../../../ludz.one_ui/Inputs/InputSwitch";
import { InputSwitchChangeEvent } from "primereact/inputswitch";

// import { Container } from './styles';

interface Props {
  visible: boolean;
  onOpen: () => void;
}

const ModalHelp = ({ visible, onOpen }: Props) => {
  const { initialValues, handleFormHelp, setDisplayModalForm, schema } =
    useContext(PrivateRoutesContext) as PrivateRoutesContextType;

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [checked, setChecked] = useState<boolean>(false);

  // Estrutura dos checkboxes
  const options = [
    {
      label: "Zona/Subzona",
      value: "ZonaSubzona",
      children: [
        "Painel",
        "Mural",
        "Membros",
      ],
    },
    {
      label: "Zona",
      value: "Zona",
      children: [
        "LudzFlow-Zona",
        "Conteudo",
        "Status Subzona",
      ],
    },
    {
      label: "Subzona",
      value: "Subzona",
      children: [
        "LudzFlow-Subzona",
        "Metas e Tarefas",
        "Quadro de Tarefas",
      ],
    },
    { label: "Explorar Canais", value: "Explorar Canais", children: [] },
    { label: "Explorar Zonas", value: "Explorar Zonas", children: [] },
  ];

  // Atualiza as seleções
  const handleCheckboxChange = (value: string) => {
    setSelectedOptions((prev: string[]) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  return (
    <Modal visible={visible} onOpen={onOpen} title="Ajuda do Sistema">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const formatSelections = (selectedOptions: string[]) => {
            if (selectedOptions.length === 0) return "";
            return options
              .filter(
                (option) =>
                  selectedOptions.includes(option.value) ||
                  option.children.some((child) =>
                    selectedOptions.includes(child)
                  )
              )
              .map((option) => {
                const children = option.children
                  .filter((child) => selectedOptions.includes(child))
                  .map((child) => `  - ${child}`)
                  .join("\n");

                return `- ${option.label}${children ? `\n${children}` : ""}`;
              })
              .join("\n");
          };

          const formattedSelections = formatSelections(selectedOptions);

          // Monta a string final de forma condicional
          const concatenatedDescription = `
  ${
    formattedSelections
      ? `Opções selecionadas:\n${formattedSelections}\n\n`
      : ""
  }Descrição:
  "${values.message}"
      `.trim(); // Remove espaços extras

          console.log("Resultados obtidos: ", concatenatedDescription);

          handleFormHelp({ ...values, message: concatenatedDescription });
          setDisplayModalForm(false);
        }}
        validationSchema={schema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          errors,
          touched,
        }) => {
          return (
            <form onSubmit={handleSubmit} style={{ overflowY: "auto" }}>
              <div className="mb-2">
                <p>Funções do sistema </p>
                <p className="text-sm font-italic">
                  Caso queira especificar melhor sua solicitação
                </p>
              </div>
              <div className="mb-2">
                <h6 className="mb-1">Exibir/Ocultar</h6>
                <LudzInputSwitch
                  checked={checked}
                  onChange={(e: InputSwitchChangeEvent) => setChecked(e.value)}
                />
              </div>
              {/* Renderizar os checkboxes hierárquicos */}
              {checked && (
                <>
                  {options.map((option) => (
                    <div key={option.value} style={{ marginBottom: "8px" }}>
                      <label>
                        <CheckboxLudz
                          style={{ marginRight: "6px" }}
                          checked={selectedOptions.includes(option.value)}
                          onChange={() => handleCheckboxChange(option.value)}
                        />
                        {option.label}
                      </label>
                      {option.children.length > 0 && (
                        <div style={{ paddingLeft: "20px", marginTop: "6px" }}>
                          {option.children.map((child) => (
                            <label key={child} style={{ display: "block" }}>
                              <CheckboxLudz
                                style={{
                                  marginRight: "6px",
                                  marginBottom: "4px",
                                }}
                                checked={selectedOptions.includes(child)}
                                onChange={() => handleCheckboxChange(child)}
                              />
                              {child}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}

              <InputTextArea
                label="Descrição*"
                name="message"
                value={values.message}
                onChange={handleChange}
                style={{ width: "100%" }}
                placeholder="Digite aqui sua sugestão, ajuda, questionamento..."
                className={
                  errors.message && touched.message ? "p-invalid" : ""
                }
              />
              <Padding />
              {errors.message && touched.message && (
                <div style={{ color: "red" }}>{errors.message}</div>
              )}
              <Row id="end">
                <Row>
                  <Button title="Enviar" type="submit" />
                </Row>
              </Row>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ModalHelp;
