import { createContext, useMemo } from "react";
import { KanbanCardSubzonaContextType } from "./type";
import { InsiderCardState } from "./state";

export const KanbanCardSubzonaContext = createContext<KanbanCardSubzonaContextType | null>(null);

interface Props {
    children: React.ReactNode;
}

const KanbanCardSubonaProvider = ({ children }: Props) => {
    const { 
        handleArchives, 
        card, 
        setcard,
        deleteLabels, 
        handleUpdateCard, 
        handleAddMember, 
        deleteMemberCard, 
        visibleMember, 
        setvisibleMember, 
        setvisibleTags, 
        visibleTags, 
        handleLabels, 
        setvisibleMetas, 
        visibleMetas, 
        deleteMetas, 
        handleMetas, 
        deleteComments, 
        handleComments, 
        setvisibleArchives, 
        visibleArchives, 
        deleteArchives, 
        setFiles, 
        handleChecklist, 
        visibleChecklist, 
        setvisibleChecklist, 
        handleUpdateChecklists, 
        deleteChecklists, 
        handleChecklistItem, 
        handleUpdateChecklistItem, 
        deleteChecklistItems, 
        handleUpdateDate, 
        visibleDate, 
        setvisibleDate, 
        toast, 
        toastKanbanCard,
    } = InsiderCardState();

    const contextValue = useMemo(() => ({
        handleArchives,
        card,
        setcard,
        deleteLabels,
        handleUpdateCard,
        handleAddMember,
        deleteMemberCard,
        visibleMember,
        setvisibleMember,
        setvisibleTags,
        visibleTags,
        handleLabels,
        setvisibleMetas,
        visibleMetas,
        deleteMetas,
        handleMetas,
        deleteComments,
        handleComments,
        setvisibleArchives,
        visibleArchives,
        deleteArchives,
        setFiles,
        handleChecklist,
        visibleChecklist,
        setvisibleChecklist,
        handleUpdateChecklists,
        deleteChecklists,
        handleChecklistItem,
        handleUpdateChecklistItem,
        deleteChecklistItems,
        handleUpdateDate,
        visibleDate,
        setvisibleDate,
        toast,
        toastKanbanCard,
    }), [handleArchives, card, setcard, deleteLabels, handleUpdateCard, handleAddMember, deleteMemberCard, visibleMember, setvisibleMember, setvisibleTags, visibleTags, handleLabels, setvisibleMetas, visibleMetas, deleteMetas, handleMetas, deleteComments, handleComments, setvisibleArchives, visibleArchives, deleteArchives, setFiles, handleChecklist, visibleChecklist, setvisibleChecklist, handleUpdateChecklists, deleteChecklists, handleChecklistItem, handleUpdateChecklistItem, deleteChecklistItems, handleUpdateDate, visibleDate, setvisibleDate, toast, toastKanbanCard]);

    return (
        <KanbanCardSubzonaContext.Provider value={contextValue}>
            {children}
        </KanbanCardSubzonaContext.Provider>
    );
}

export default KanbanCardSubonaProvider;
