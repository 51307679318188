import { useQuery } from "react-query";
import { GetUserBffRequest, GetUserLimitAllSizeBffRequest, GetUserLimitSizeBffRequest } from "./request";

export const useFetchUserBff = (id: string | undefined) => {
    return useQuery(["UseZoneIdBff", id], () => GetUserBffRequest(id));
};

export const useFetchUserLimitSizeBff = () => {
    return useQuery(["UseZoneIdLimitSizeBff"], () => GetUserLimitSizeBffRequest());
};


export const useFetchUserLimitAllSizeBff = () => {
    return useQuery(["UserZoneIdLimitAllSizeBff"], () => GetUserLimitAllSizeBffRequest())
}