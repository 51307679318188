import { useContext, useState } from "react";

import { Form, Formik } from "formik";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { SessionZonaContext } from "../../../../../../../contexts/Zone/SessionZone/context";
import { SessionZonaContextType } from "../../../../../../../contexts/Zone/SessionZone/type";
import {
  Button,
  Icon,
  Input,
  Modal,
  UploadFile,
} from "../../../../../../../ludz.one_ui";
import { Padding } from "../../../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../../../styles/globalStyles";
import VideoList from "../VideoList";
import { CreateContentContext } from "./context";
import { useMediaQuery } from "@mui/material";
interface PropsCreateSectionContent {
  visible?: any;
  onOpen?: any;
  index: number;
  data?: any;
  set?: any;
}

const CreateSessionContents = ({
  visible,
  onOpen,
  index,
}: PropsCreateSectionContent) => {
  const { initialValuesContent, handleSessionContentsFile, schema } =
    useContext(SessionZonaContext) as SessionZonaContextType;
  const [files, setFiles] = useState();

  const [state, setState] = useState(0);

  const [video, setVideo] = useState<any>();

  const AddVideo = (setFieldValue: any, video: any) => {
    setVideo(video);
    setFieldValue("playlist_content", video.id);
  };

  const matches = useMediaQuery("(max-width: 520px)")

  return (
    <Modal visible={visible} onOpen={onOpen} title={"Criar conteúdo"}>
      <Formik
        initialValues={
          state === 2
            ? { ...initialValuesContent, playlist_content: undefined }
            : initialValuesContent
        }
        validationSchema={schema}
        onSubmit={(values) => {
          handleSessionContentsFile(
            visible?.id,
            index,
            files ? files![0] : undefined,
            onOpen,
            values.title!,
            values.playlist_content
          );
          onOpen();
          setFiles(undefined);
        }}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => {
          return (
            <CreateContentContext.Provider value={{ AddVideo, setFieldValue, video }}>
              <Form>
                <Padding />
                <Column>
                  <Input
                    name="title"
                    value={values.title}
                    label="Título"
                    onChange={handleChange}
                    placeholder="Escreva o título do seu conteúdo"
                  />
                </Column>
                {errors.title && touched.title ? (
                  <div style={{ color: "red" }}>{errors.title.toString()}</div>
                ) : null}
                <Padding padding="16px" />
                <label>Tipo de conteúdo</label>
                <Padding padding="8px" />
                <Row style={{ flexDirection: matches ? 'column' : 'row' }}>
                  <Types
                    name="Texto"
                    icon="pi pi-pencil"
                    state={state}
                    value={0}
                    onChange={() => {
                      setState(0);
                      setFiles(undefined)
                    }}
                  />
                  <Padding padding="8px" />
                  <Types
                    icon="pi pi-file-import"
                    name="Arquivo"
                    state={state}
                    value={1}
                    onChange={() => {
                      setState(1);

                    }}
                  />
                  <Padding padding="8px" />
                  <Types
                    icon="pi pi-video"
                    name="Video"
                    state={2}
                    value={state}
                    onChange={() => {
                      setState(2);
                      setFiles(undefined)
                    }}
                  />
                </Row>
                <Padding padding="8px" />
            
                {state === 1 ? (
                  <UploadFile setFile={setFiles} />
                ) : state === 2 ? (
                  <VideoList />
                ) : null}
                <Padding padding="8px" />
                <Row id="center">
                  <Button
                    title="Criar"
                    type="submit"
                    // onClick={() => {handleSessionContentsFile(visible?.id, index, files ? files![0] : undefined, onOpen, values.title!);onOpen();}}
                  />
                </Row>
              </Form>
            </CreateContentContext.Provider>
          );
        }}
      </Formik>
    </Modal>
  );
};

const Types = ({
  icon,
  name,
  onChange,
  value,
  state,
}: {
  name: string;
  icon?: any;
  value: number;
  onChange?(event: RadioButtonChangeEvent): void;
  state: number;
}) => {
  return (
    <div>
      <div className="flex align-items-center">
        <Row id="center">
          {icon ? (
            <Icon icon={icon} />
          ) : (
            <h3 style={{ fontSize: "24px" }}>T</h3>
          )}
        </Row>
        <Padding />
        <RadioButton
          inputId="ingredient1"
          name="pizza"
          value={value}
          onChange={onChange}
          checked={state === value}
        />
        <h4 className="ml-2">
          {name}
        </h4>
      </div>
    </div>
  );
};

export default CreateSessionContents;
