import http from "../../../../../services/api";
import { logout } from "../../../../../services/localStorage";
import { PropsKanbanCardLabels } from "./type";

export const SubzoneLabelsKanbanCardRequest = async (body: PropsKanbanCardLabels) => {
    try {
        const response = await http.post("/direct/kanban-card-labels", body);
        return response.data;
    } catch (err: any) {
        if (err.response?.status === 401) {
            logout();
            window.location.reload();
        }
        throw err;
    }
}

export const DeleteSubzoneLabelsKanbanCardRequest = async (id: number) => {
    try {
        const response = await http.delete(`/direct/kanban-card-labels/${id}`);
        return response.data;
    } catch (err: any) {
        if (err.response?.status === 401) {
            logout();
            window.location.reload();
        }
        throw err;
    }
}
