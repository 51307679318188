import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DeleteSubZoneController, UpdateSubZoneController } from "../../../ludzoneSDK/Subzone/controller";
import { StatusBffController } from "../../../ludzoneSDK/Zone/StatusSubzone/controller";
import { Toast } from "primereact/toast";
// import { ControllerStatusSubzona } from "../../../../ludz.one_sdk/controllers/Zona/ControllerStatusSubzona"

export const StatusSubzoneState = () => {

    const { id } = useParams()
    const toast = useRef<Toast>(null)
    const { subzoneStatusRequest, refetch } = StatusBffController(parseInt(id!))
    const { UpdateSubZoneMutation } = UpdateSubZoneController({ refetch: refetch }, {toast: toast})
    const { DeleteSubZoneMutation } = DeleteSubZoneController({ refetch: refetch }, {toast: toast})
    const [subzone, setsubzone] = useState<any[]>([])


    useEffect(() => {
        if (subzoneStatusRequest) {
            setsubzone(subzoneStatusRequest)
        }
    }, [subzoneStatusRequest])

    const [statusSelect, setStatusSelect] = useState<{
        name: string;
        id: number;
    }>({ name: "Analise", id: 1 })

    const status = [
        { name: "Aprovado", id: 2 },
        { name: "Analise", id: 1 },
        { name: "Reprovado", id: 3 }
    ]


    const handleAvaliate = (id: string, status: number) => {

        if (subzone) {
            const novosItems = subzone.map((item: any) => {
                if (item.id === parseInt(id)) {
                    return { ...item, status: status };
                }
                return item;
            });

            setsubzone(novosItems);
        }

        UpdateSubZoneMutation.mutate({
            id: id, body: {
                status: status
            }
        })
    }

    const deleteSubzona = (id: string) => {
        DeleteSubZoneMutation.mutate(id)
    };

    return {
        status, statusSelect, setStatusSelect, handleAvaliate, deleteSubzona, subzone, toast
    }
}