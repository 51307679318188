import http from "../../../services/api";
import { logout } from "../../../services/localStorage";
import { PropsHelp } from "./types";

export const FormHelpRequest = async (body: PropsHelp) => {
    return await http.post("/bff/aux-bff/help-email", body).then(response => response.data)
         .catch(err => {
              if (err.response.status === 401) {
                   logout()
                   window.location.reload()
              }
              alert(err.response.data.message)
              console.log(err)
              throw err;
         });
}