import { useMutation } from "react-query";
import { PropsHelp } from "./types";
import { FormHelpRequest } from "./request";
import Swal from "sweetalert2";

export const FormHelpController = () => {

    const FormHelpMutation = useMutation((data: PropsHelp) => FormHelpRequest(data), {
        onMutate: () => {
        //   setLoading(true); 
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);

        Swal.fire({
            icon: "warning",
            title: "Erro ao enviar o formulário, tente novamente!",
            showConfirmButton: false,
            timer: 3500,
          });
        },
        onSuccess: (result, variables, context) => {
            Swal.fire({
                icon: "success",
                title: "Seu formulário foi enviado, Entraremos em contato em breve!",
                showConfirmButton: false,
                timer: 3500,
              });
        },
      });

    return {
        FormHelpMutation,
    }
}