import { useFetchUserBff, useFetchUserLimitAllSizeBff, useFetchUserLimitSizeBff } from "./query";





export const UserBffController = (id: string | undefined) => {

    const { data, refetch } = useFetchUserBff(id)

    return{
        data, refetch
    }
}

export const UserLimitSizeBffController = () => {

    const { data, refetch } = useFetchUserLimitSizeBff()

    return{
        data, refetch
    }
}

export const UserLimitAllSizeBffController = () => {
    const { data, refetch } = useFetchUserLimitAllSizeBff()

    return {
        data, refetch
    }
}