import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../contexts/PrivateRoutes/types";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import IconsMenu from "../IconsMenu";
import LogoLudus from "../Logo";
import ZonaMenu from "../Zonas";
import { MenuLeftStyle, Separator } from "../style";
import { Tooltip } from "../../../ludz.one_ui";
import ModalHelp from "../ModalHelp";

const MenuLeft = () => {
  const { zonesProvider, setDisplayModalForm, displayModalForm } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;
  const history = useNavigate();

  return (
    <MenuLeftStyle>
      {/* <Tooltip title="Inicio" arrow="right"> */}
      {/* <IconButton> */}
      <Tooltip title="Tela Inicial" placement="right">
        <Link to={"/home"}>
          <LogoLudus logo />
        </Link>
      </Tooltip>
      <Separator />
      {zonesProvider?.map((item, index) => {
        return <ZonaMenu key={index} zona={item} />;
      })}

      <Tooltip title="Criar Zona">
        <IconsMenu onClick={() => history("/criar/zona")} icon="addZona" />
      </Tooltip>
      <Separator />
      <Tooltip title="Explorar Zonas">
        <IconsMenu icon="explore" onClick={() => history("/home/zonaspublicas")} />
      </Tooltip>
      <IconsMenu icon="channels" onClick={() => history("/home/canaispublicos")} />
      <Tooltip title="Ajuda">
        <IconsMenu icon="help" onClick={() => setDisplayModalForm(true)}/>
      </Tooltip>
      <Padding padding="8px" />
      <ModalHelp visible={displayModalForm} onOpen={() => setDisplayModalForm(!displayModalForm)} />
    </MenuLeftStyle>
  );
};

export default MenuLeft;
