import { InputText } from "primereact/inputtext";
import React from "react";
import { Padding } from "../styles/styled-components";

interface InputTextAttributis {
    label?: string,
    onChange?: any,
    onBlur?: any,
    placeholder?: string,
    onKeyUp?: () => void,
    onKeyDown?: any,
    style?: React.CSSProperties,
    value?: string,
    defaultValue?: string,
    type?: any,
    name?: string,
    className?: string,
    accept?: string
    maxLength?: any
    ref?: any
}

const Input = ({ className, ref, value, onChange, accept, type, name, onBlur, defaultValue, placeholder, style, onKeyUp, onKeyDown, label, maxLength, ...props }: InputTextAttributis) => {
    return (
        <>
            {label ? <>
                <label>{label}</label>
                <Padding padding="2px" />
            </> : null}
            <InputText
                ref={ref}
                className={className} accept={accept} value={value} type={type} name={name} onKeyDown={onKeyDown} onChange={onChange} onKeyUp={onKeyUp} onBlur={onBlur} style={{ ...style, width: '100%', backgroundColor: "#F6F7F7", height: "44px" }} defaultValue={defaultValue} placeholder={placeholder}
                {...props}
                maxLength={maxLength}
            />
        </>

    )
}

export default Input