import { useContext } from "react";
import { Button, Input, Modal } from "../../../../../ludz.one_ui";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../styles/globalStyles";
import { SessionZonaContext } from "../../../../../contexts/Zone/SessionZone/context";
import { SessionZonaContextType } from "../../../../../contexts/Zone/SessionZone/type";
import { Form, Formik } from "formik";
import { ZonaContext } from "../../../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../../../contexts/Zone/Zone/type";
import * as Yup from "yup";

interface PropsCreateSection {
  visible?: boolean;
  onOpen?: any;
}

const CreateSession = ({ visible, onOpen }: PropsCreateSection) => {
  const { zone } = useContext(ZonaContext) as ZonaContextType;

  const { handleSession, initialValues, session } = useContext(
    SessionZonaContext
  ) as SessionZonaContextType;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(40, "Máximo de 40 caracteres permitidos") // Limite de 40 caracteres
      .required("O nome da seção é obrigatório"),
  });

  return (
    <Modal visible={visible} onOpen={onOpen} title={"Criar Seção"}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSession({...values, index: session?.length!}, zone!.id, onOpen)}
      >
        {({ values, handleChange, errors, touched }) => {
          return (
            <Form>
              <Column>
                <Input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  label="Nome da seção"
                  maxLength={40}
                />
                <p className="font-semibold">{values.name.length} / 40 caracteres</p>
              </Column>
              <Padding padding="16px" />
              {errors.name && touched.name && (
                <div style={{ color: "red" }}>{errors.name.toString()}</div>
              )}
              <Padding />
              <Row id="center">
                <Button title="Criar" type="submit" />
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateSession;
