import styled from "styled-components";

export const Container = styled.div`
  padding: 10px 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;

  @media (max-width: 1024px) {
      padding: 10px 10%;
    }


  @media (max-width: 920px) {
    padding: 10px 5%;
  }

  @media (max-width: 520px) {
    padding: 0px;
  }
`;

export const ChatBox = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  width: 100%;

  @media (max-width: 520px) {
    padding: 10px;
  }
`;
