import http from "../../../../services/api"
import { logout } from "../../../../services/localStorage";
import { uploadArchive } from "../../../../services/upload-archives";
import { PropsChangeIndexSessionContent, PropsSessionContentText } from "./type"

export const SessionContentsZoneRequest = async (body: PropsSessionContentText, file?: File) => {

    if (file) {
        return await uploadArchive(file, "/bff/session-contents-bff", body, "POST");
    }
    return await http.post("/bff/session-contents-bff", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err)
            throw err;
        });
}
export const GetZoneSessionsContentsRequest = async (id: number) => {
    if (id) {
        return await http.get("/bff/session-bff/content-one", { params: { contentId: id } }).then(response => response.data)
            .catch(err => {
                if (err.response.status === 401) {
                    logout()
                    window.location.reload()
                }
                throw err;
            });
    }
}

export const ChangeIndexSessionContentZoneRequest = async (body: PropsChangeIndexSessionContent) => {
    return await http.put("/bff/session-contents-bff/change-index/" + body.sessionContentId, { newIndex: body.newIndex }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err)
            throw err;
        });
}

export const DeleteSessionContentsZoneRequest = async (id: string) => {
    return await http.delete(`/bff/session-contents-bff/${id}`).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err)
            throw err;
        });
}

export const EditTextSessionContentZoneRequest = async (body: { textContent?: string | null, title?: string }, id: number) => {
    return await http.put(`/direct/session-contents/${id}`, body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err)
            throw err;
        });
}