import { Toast } from "primereact/toast";
import { RefObject } from "react";
import { useMutation } from "react-query";
import { Alert } from "../../../../components/Alert";
import queryClient from "../../../../services/react-query";
import { verifySizeZone } from "../../../../services/upload-archives";
import { ChangeIndexSessionContentZoneRequest, EditTextSessionContentZoneRequest, SessionContentsZoneRequest } from "./request";
import { PropsChangeIndexSessionContent, PropsSessionContentText } from "./type";

export const SessionContentsZoneController = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  }, id?: number) => {

    

    const ChangeIndexSessionContentZoneMutation = useMutation((data: PropsChangeIndexSessionContent) => ChangeIndexSessionContentZoneRequest(data), {
        onMutate: () => {
        },
        onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result: any) => {
              queryClient.refetchQueries("UseZoneSessionBff")
        },
    });

    const SessionContentsZoneMutation = useMutation(async ({data, file}:{data: PropsSessionContentText, file?: File}) => {
        if (file) {
            return await verifySizeZone(file, id).then((item) => {
                return SessionContentsZoneRequest(data, file)
            }).catch((error) => {
                throw new Error("UploadLimitExceeded");
            })
        } else {
           return SessionContentsZoneRequest(data)
        }
    }, {
        onMutate: () => {
        },
        onError: (error: any) => {
            if (error.message === "UploadLimitExceeded") {
                Alert({
                    summary: "Atenção",
                    detail: "A zona excedeu o limite de upload",
                    severity: "warn",
                    toast: toast
                }).show();
            } else {
                Alert({
                    summary: "Erro",
                    detail: "Aconteceu algum problema, tente novamente",
                    severity: "error",
                    toast: toast
                }).show();
                console.log(error);
            }
            console.log(error)
        },
        onSuccess: (result: any) => {
            Alert({
                detail: "Conteúdo criado!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
            }).show();
            queryClient.refetchQueries("UseZoneSessionBff");
            console.log("jsana")
        },
    });

    return {
        SessionContentsZoneMutation, ChangeIndexSessionContentZoneMutation
    }
}

interface PropsEditSessionContentsText {
    id: number,
    body: { textContent?: string | null, title?: string }
}

export const EditSessionContentsText = () => {

    const EditSessionContentsZoneMutation = useMutation(({ id, body }: PropsEditSessionContentsText) => EditTextSessionContentZoneRequest(body, id), {
        onMutate: () => {
        },
        onError: (error: any) => {
            
            console.log(error)
        },
        onSuccess: (result: any) => {
          
            queryClient.refetchQueries("UseZoneSessionBff");
            queryClient.refetchQueries("UseZoneSessionContents");
            
        },
    });

    return {
        EditSessionContentsZoneMutation, 
    }
}