import { Tooltip, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { KanbanCardSubzonaContext } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { Icon } from "../../../../../../../ludz.one_ui";
import { AddButton, Label } from "../style";

const MetasCard = () => {
  const { visibleMetas, setvisibleMetas, card } = useContext(
    KanbanCardSubzonaContext
  ) as KanbanCardSubzonaContextType;

  const matches = useMediaQuery("(max-width: 520px)")

  const objetiveCard = card?.objectives[0]?.objective_key;

  function getContrastColor(bgColor: any) {

    if (!bgColor) { return ''; }
    const rgb = parseInt(bgColor.replace('#', ''), 16);   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >>  8) & 0xff;  // extract green
    const b = (rgb >>  0) & 0xff;  // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    return luma < 128 ? 'white' : '#4F4F4F';
}

// No seu componente React
const bgColor = objetiveCard?.color; // A cor de fundo que você quer verificar
const [textColor, setTextColor] = useState(getContrastColor(bgColor));

useEffect(() => {
    setTextColor(getContrastColor(bgColor));
}, [bgColor]);



  return (
    <div>
      <h4>Metas</h4>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "8px 8px",
        }}
      >
        {card?.objectives?.length > 0 && (
          <Tooltip title={objetiveCard?.content}>
            <Label color={objetiveCard?.color}   style={{
                flexGrow: 1,
                minWidth: "150px", // Tamanho mínimo para a responsividade
                maxWidth: "60%", // Máximo permitido no layout flexível
                textAlign: 'center',
                height: '50px',
                padding: matches ? '4px 4px 8px 4px' : '4px',
                whiteSpace: matches ? 'normal' : 'nowrap', // Evita quebra de linha
                overflow: 'hidden', // Oculta conteúdo que ultrapassa o limite
              }}  >
                <h4 className="my-auto" style={{ color: `${textColor}` }}>{objetiveCard?.content.length > 30 ? objetiveCard?.content.substring(0, 30) + "..." : objetiveCard?.content}</h4>
            </Label>
          </Tooltip>
        )}
        <>
          {/* {card.members.map((item, index) => { */}
          {/* return ( */}
          {/* <div
                                        // onClick={
                                        //     () => HandleDeleteUser(item)}
                                        style={{ cursor: "pointer" }}
                                        key={index}
                                    >
                                        <WrapMembers>
                                            <ImageUser card={item} index={index} />
                                            <img alt="" key={index} title={'Remover membro'} src={item.avatar_url} />
                                            </WrapMembers>
                                        </div> */}
          {/* ); */}
          {/* })} */}
        </>

        <AddButton onClick={() => setvisibleMetas(!visibleMetas)}>
          <Icon size="1rem" icon="pi pi-plus" style={{ margin: "auto" }} />
        </AddButton>
      </div>
    </div>
  );
};

export default MetasCard;
