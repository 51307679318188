import { memo, useContext, useState, useMemo, useCallback } from "react";
import { KanbanCardSubzonaContext } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { KanbanSubzonaContext } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/context";
import { KanbanSubzonaContextType } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/type";
import CheckboxLudz from "../../../../../../../../../ludz.one_ui/Checkboxs";
import ConfirmationDialog from "../../../../../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import { Column, Row } from "../../../../../../../../../styles/globalStyles";
import { ButtonTags } from "../../../style";
import { CheckboxChangeEvent } from "primereact/checkbox";

interface TagItem {
    id: number;
    name: string;
    color: string;
}

const Tags = memo(({ item }: { item: TagItem }) => {
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [pendingUpdates, setPendingUpdates] = useState(false);
    const { deleteTags } = useContext(KanbanSubzonaContext) as KanbanSubzonaContextType;

    const { card, handleLabels, deleteLabels } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType;
    const [cardtag, setCardTag] = useState(card)

    // Usando useMemo para memorizar o valor de isLabel e evitar re-renderizações desnecessárias
    const isLabel = useMemo(() => cardtag?.labels?.find((i: any) => i.label.id === item.id), [cardtag?.labels, item.id]);

    function getContrastColor(bgColor: string) {
        if (!bgColor) return '';
        const rgb = parseInt(bgColor.replace('#', ''), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;

        const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luma < 128 ? 'white' : '#C0C0C0';
    }

    const bgColor = item.color;
    const textColor = getContrastColor(bgColor);

    const handleLabelChange = useCallback((event: CheckboxChangeEvent) => {
        const newChecked = event.target.checked;
        const prevLabels = [...cardtag.labels]; // Clona as labels atuais para reverter se necessário

        setPendingUpdates(true); // Define que há atualizações pendentes

        if (newChecked) {
            const updatedLabels = [...prevLabels, { label: item }];
            setCardTag((prevCard: any) => ({ ...prevCard, labels: updatedLabels })); // Atualiza o estado do card

            try {
                handleLabels({ label: item?.id, kanbanCard: cardtag?.id });
            } catch (error) {
                setCardTag((prevCard: any) => ({ ...prevCard, labels: prevLabels })); // Reverte o estado do card
                alert("Erro ao adicionar a tag.");
            } finally {
                setPendingUpdates(false); // Finaliza a atualização pendente
            }
        } else {
            const updatedLabels = prevLabels.filter((i: any) => i.label.id !== item.id);
            setCardTag((prevCard: any) => ({ ...prevCard, labels: updatedLabels })); // Atualiza o estado do card

            try {
                deleteLabels(isLabel?.id);
            } catch (error) {
                setCardTag((prevCard: any) => ({ ...prevCard, labels: prevLabels })); // Reverte o estado do card
                alert("Erro ao remover a tag.");
            } finally {
                setPendingUpdates(false); // Finaliza a atualização pendente
            }
        }
    }, [cardtag?.id, cardtag.labels, deleteLabels, handleLabels, isLabel?.id, item])

    return (
        <Row>
            <Column id="center">
                {pendingUpdates ? 
                <i className="pi pi-spinner" />
                : <CheckboxLudz
                    checked={!!isLabel}
                    onChange={handleLabelChange}
                    disabled={pendingUpdates} // Desabilita enquanto há atualizações pendentes
                />}
                
            </Column>

            <Column style={{ width: "100%" }}>
                <ButtonTags style={{ backgroundColor: bgColor }}>
                    <h5 style={{ color: textColor }}>{item.name}</h5>
                </ButtonTags>
            </Column>
            <Column id="center">
                <i
                    className="pi pi-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => setVisibleDelete(true)}
                ></i>
                <ConfirmationDialog
                    visible={visibleDelete}
                    message="Deseja excluir essa tag?"
                    accept={() => deleteTags(item.id)}
                    reject={() => setVisibleDelete(false)}
                />
            </Column>
        </Row>
    );
});

export default Tags;
