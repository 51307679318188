import { useMutation } from "react-query";
import queryClient from "../../../../../services/react-query";
import { PropsKanbanCardLabels } from "./type";
import { DeleteSubzoneLabelsKanbanCardRequest, SubzoneLabelsKanbanCardRequest } from "./request";

export const LabelsKanbanCarController = () => {

    const KanbanCardLabelsSubzoneMutation = useMutation((data: PropsKanbanCardLabels) => SubzoneLabelsKanbanCardRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: { response: { data: { message: string }}}) => {
            console.log(error)
            // setLoading(false); // se estiver usando um estado de loading
        },
        
        onSuccess: (result) => {
            queryClient.invalidateQueries("UseSubzoneKanbanbff");
            queryClient.invalidateQueries("UseSubzoneKanbanCard");
        },
        
    });

    const DeleteKanbanCardLabelsSubzoneMutation = useMutation((id: number) => DeleteSubzoneLabelsKanbanCardRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: { response: { data: { message: string }}}) => {
           console.log(error)
            // setLoading(false); // se estiver usando um estado de loading
        },
        
        onSuccess: (result) => {
            queryClient.invalidateQueries("UseSubzoneKanbanbff");
            queryClient.invalidateQueries("UseSubzoneKanbanCard");
        },
        
    });

    return {
        KanbanCardLabelsSubzoneMutation, DeleteKanbanCardLabelsSubzoneMutation
    }
}