import { useMutation } from "react-query";
import { PropsMemberCard } from "./type";
import queryClient from "../../../../../services/react-query";
import {
  DeleteMemberCardRequest,
  SubzoneMemberKanbanCardRequest,
} from "./request";

export const MemberKanbanCardController = () => {
  const KanbanCardSubzoneMutation = useMutation(
    (data: PropsMemberCard) => SubzoneMemberKanbanCardRequest(data),
    {
      onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
      },
      onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);
      },
      onSuccess: (result) => {
        queryClient.invalidateQueries("UseSubzoneKanbanbff");
        queryClient.invalidateQueries("UseSubzoneKanbanCard");
      },
    }
  );

  const DeleteKanbanMemberCardSubzoneMutation = useMutation(
    (id: number) => DeleteMemberCardRequest(id),
    {
      onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
      },
      onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);
       
      },
      onSuccess: (result) => {
        queryClient.invalidateQueries("UseSubzoneKanbanbff");
        queryClient.invalidateQueries("UseSubzoneKanbanCard");
      },
    }
  );

  return {
    KanbanCardSubzoneMutation,
    DeleteKanbanMemberCardSubzoneMutation,
  };
};
