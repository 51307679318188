// MeterStyles.tsx
import styled from 'styled-components';

interface MeterFillProps {
  value: number;
  color?: string;
}

interface PointLabelProps {
    color?: string;
}

export const MeterContainer = styled.div`
  width: 100%;
  margin: 10px 0;
`;

export const MeterLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

export const PointLabel = styled.p<PointLabelProps>`
    color: ${( { color }) => color ? color : '#007bff'};
    font-size: 40px;
`;

export const MeterBar = styled.div`
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 20px;
  position: relative;
`;

export const MeterFill = styled.div<MeterFillProps>`
  background-color: ${( { color }) => color ? color : '#007bff'};
  height: 100%;
  border-radius: 10px;
  transition: width 0.3s ease-in-out;
  width: ${({ value }) => value}%;
`;
