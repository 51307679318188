import { useEffect, useRef, useState } from "react";
import { UpdateNotificationController } from "../../ludzoneSDK/User/Notifications/controller";
import { UserHomeController } from "../../ludzoneSDK/User/controller";
import {
  ChannelsBusiness,
  Invite,
  Message,
  Notifications,
  PropsInitialValue,
  PropsUser,
  ZoneUser,
} from "./types";
import {
  useFetchUserBusinessChannel,
  useFetchUserChannel,
} from "../../ludzoneSDK/User/query";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import { FormHelpController } from "../../ludzoneSDK/User/Help/controller";

export const PrivateRoutesStates = () => {
  const [messages, setMessages] = useState<Message[] | undefined>();
  const [input, setInput] = useState("");
  const [invites, setinvites] = useState<Array<Invite> | undefined>();
  const [notifications, setNotifications] = useState<
    Array<Notifications> | undefined
  >();
  const [userProvider, setUserProvider] = useState<PropsUser | undefined>();
  const [zonesProvider, setZonesProvider] = useState<
    Array<ZoneUser> | undefined
  >();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [userChannel, setUserChannel] = useState<
    ChannelsBusiness | undefined
  >();

  const [userBusinessChannel, setUserBusinessChannel] = useState<
    ChannelsBusiness | undefined
  >();

  const toast = useRef<Toast>(null);

  const { user, invitesRequest, notificationsRequest } = UserHomeController();
  const {
    UpdateNotificationMutation,
    UpdateAllNotificationMutation,
    DeleteNotificationMutation,
  } = UpdateNotificationController({ toast: toast });
  const { data: channelsUserBusiness } = useFetchUserBusinessChannel(
    user?.role
  );
  const { data: channelsUser } = useFetchUserChannel();

  const [displayModalForm, setDisplayModalForm] = useState(false);
  const initialValues: PropsInitialValue = {
    message: "",
  };

  const schema = Yup.object().shape({
    message: Yup.string().required("A descrição é obrigatória"),
  });

  const { FormHelpMutation } = FormHelpController();

  const handleFormHelp = (body: PropsInitialValue) => {
    FormHelpMutation.mutate(body);
    setDisplayModalForm(false);
  };

  useEffect(() => {
    if (user) {
      setUserProvider(user);
      setZonesProvider(user.zoneUser);
    }
    if (invitesRequest) {
      setinvites(invitesRequest);
    }
    if (notificationsRequest) {
      setNotifications(notificationsRequest);
    }
    if (channelsUser) {
      setUserChannel(channelsUser);
    }
    if (channelsUserBusiness) {
      setUserBusinessChannel(channelsUserBusiness);
    }
  }, [
    user,
    invitesRequest,
    notificationsRequest,
    channelsUser,
    channelsUserBusiness,
  ]);

  const UpdateNotifcation = (id: string, body: { isViewed: boolean }) => {
    UpdateNotificationMutation.mutate({ id: id, body: body });
  };

  const UpdateAllNotification = () => {
    UpdateAllNotificationMutation.mutate();
  };

  const DeleteSelectionNotifications = (ids: number[]) => {
    ids.forEach((item: number) => {
      const updateNotifications = notifications?.filter(
        (obj) => obj.id !== item
      );
      setNotifications(updateNotifications);
      DeleteNotificationMutation.mutate(item.toString());
    });
  };

  return {
    userProvider,
    zonesProvider,
    activeIndex,
    userChannel,
    setActiveIndex,
    invites,
    notifications,
    UpdateNotifcation,
    UpdateAllNotification,
    userBusinessChannel,
    DeleteSelectionNotifications,
    messages,
    setMessages,
    input,
    setInput,
    toast,
    initialValues,
    schema,
    handleFormHelp,
    displayModalForm,
    setDisplayModalForm,
  };
};
