import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card } from "../../../../ludzoneSDK/Subzone/Kanban/type";
import CardKanban from "../KanbanList/Card";
import { CardStyle } from "../style";
import { Id } from "../types";
import { StyledDraggingCard } from "./style";
import { useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

interface Props {
  task: Card;
  deleteTask: (id: Id) => void;
  updateTask: (id: Id, content: string) => void;
}

function TaskCard({ task }: Props) {
  const matchesMobile = useMediaQuery("(max-width: 520px)");
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: {
      type: "Task",
      task,
    },
  });

  const history = useNavigate();

  const { idSubzone, id } = useParams();

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return <StyledDraggingCard ref={setNodeRef} style={style} />;
  }

  if (matchesMobile) {
    return (
      <CardStyle onClick={(e) => {
        // e.stopPropagation();
        history(`/zona/${id}/subzona/${idSubzone}/kanban/${task?.id}`);
      }}>
        <CardKanban item={task} />
      </CardStyle>
    );
  }

  return (
    <CardStyle ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <CardKanban item={task} />
    </CardStyle>
  );
}

export default TaskCard;
