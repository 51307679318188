import { useMediaQuery } from "@mui/material";

import { Dialog } from "primereact/dialog";
import { Line } from "./style";
import { Tooltip } from "../..";

interface PropsModalInsiderKanbanCard {
  visible?: boolean;
  onOpen: any;
  title?: any;
  children?: any;
}

const ModalInsiderKanbanCard = ({
  visible,
  onOpen,
  title,
  children,
}: PropsModalInsiderKanbanCard) => {
  const matches = useMediaQuery("(max-width:1150px)");

  const Header = () => {
    return (
      <>
        {title ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4>{title}</h4>
            <Tooltip title="Pressione ESC para fechar!">
              <span
                style={{
                  marginRight: "6px",
                  fontSize: "0.6rem",
                  color: "gray",
                }}
              >
                (ESC)
              </span>
            </Tooltip>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Tooltip title="Pressione ESC para fechar!">
              <span
                style={{
                  marginRight: "6px",
                  fontSize: "0.6rem",
                  color: "gray",
                }}
              >
                (ESC)
              </span>
            </Tooltip>
          </div>
        )}
      </>
    );
  };

  return (
    <Dialog
      header={Header}
      visible={visible}
      style={{ width: matches ? "70vw" : "30vw" }}
      onHide={onOpen}
    >
      <Line />
      {children}
    </Dialog>
  );
};

export default ModalInsiderKanbanCard;
