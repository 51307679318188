import { memo, useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import KanbanCardSubonaProvider from "../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanSubzonaContext } from "../../../contexts/Subzone/KanbanSubzone/context";
import { KanbanSubzonaContextType } from "../../../contexts/Subzone/KanbanSubzone/type";
import ObjectiveSubonaProvider from "../../../contexts/Subzone/ObjectiveSubzone/context";
import { Container } from "../../style";
import InsiderCard from "./KanbanList/Card/InsiderCard";
import ModalFilter from "./ModalFilter";
import KanbanBoard from "./components/KanbanBoard";
import { Toast } from "primereact/toast";
import LudzLoading from "../../../ludz.one_ui/Loading";

const Kanban = memo(() => {
  const { kanban, toast } = useContext(
    KanbanSubzonaContext
  ) as KanbanSubzonaContextType;

  const history = useNavigate();

  const [search, setSearch] = useState("");
  const [selectedObjetivos, setSelectedObjetivos] = useState<any>("");
  const [selectedMetas, setSelectedMetas] = useState<any>("");
  const [membros, setMembros] = useState<any>("");
  const [selectedTags, setSelectedTags] = useState<any>("");
  const [dates, setDates] = useState<Date[] | null>(null);
  const [status, setStatus] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(true);



  const filteredData = useMemo<
    Array<{
      kanbanCards: any[];
      title: string;
      index: number;
      id: number;
      subZone_fk: number;
    }> | undefined
  >(() => {
    // Coloque aqui o corpo da sua função datafilter
    const buscaLowerCase = search.toLowerCase();
    return (kanban || []).reduce((filteredData, props) => {
      let kanbanCardsFiltered = props.kanbanCards;

      if (status === true) {
        kanbanCardsFiltered = kanbanCardsFiltered.filter(
          (card) => card.is_finished === checked
        );
      }
      if (
        selectedObjetivos !== "" &&
        selectedObjetivos?.name !== "Sem Filtro"
      ) {
        kanbanCardsFiltered = kanbanCardsFiltered.filter((card) =>
          card.objectives.some(
            (objectivefk: any) =>
              objectivefk?.objective_fk === selectedObjetivos?.id
          )
        );
      }

      if (selectedMetas !== "" && selectedMetas?.content !== "Sem Filtro") {
        kanbanCardsFiltered = kanbanCardsFiltered.filter((card) =>
          card.objectives.some(
            (objectivekeyfk: any) =>
              objectivekeyfk?.objective_key_fk === selectedMetas?.id
          )
        );
      }

      if (membros !== "" && membros?.username !== "Sem Filtro") {
        kanbanCardsFiltered = kanbanCardsFiltered.filter((card) =>
          card.members.some((member: any) => member?.member?.id === membros?.id)
        );
      }

      if (selectedTags !== "" && selectedTags?.name !== "Sem Filtro") {
        kanbanCardsFiltered = kanbanCardsFiltered.filter((card) =>
          card.labels.some(
            (label: any) => label?.label?.id === selectedTags?.id
          )
        );
      }

      if (search !== "") {
        kanbanCardsFiltered = kanbanCardsFiltered.filter((card) =>
          card.title.toLowerCase().includes(buscaLowerCase)
        );
      }

      if (dates && dates.length > 0) {
        const startDate = new Date(dates[0]);
        const endDate = new Date(dates[1]);

        kanbanCardsFiltered = kanbanCardsFiltered.filter((card) => {
          if (!card.expectedDate) return false; // Verifica se expectedDate é undefined
          const cardDate = new Date(card.expectedDate);
          return cardDate >= startDate && cardDate <= endDate;
        });
      }

      filteredData.push({ ...props, kanbanCards: kanbanCardsFiltered });

      return filteredData;
    }, [] as Array<{ kanbanCards: any[]; title: string; index: number; id: number; subZone_fk: number }>); // Definindo o tipo do array acumulador

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    kanban,
    status,
    checked,
    selectedObjetivos,
    selectedMetas,
    membros,
    selectedTags,
    search,
    dates,
  ]); // Adicione todas as dependências aqui

  const { idSubzone, id, idcard } = useParams();

  return (
    <Container style={{ flexDirection: "row", overflowY: "hidden"}}>
      <ObjectiveSubonaProvider>
        <Toast ref={toast} />
        <ModalFilter
          kanbancard={filteredData}
          search={search}
          setSearch={setSearch}
          selectedObjetivos={selectedObjetivos}
          setSelectedObjetivos={setSelectedObjetivos}
          selectedMetas={selectedMetas}
          setSelectedMetas={setSelectedMetas}
          membros={membros}
          setMembros={setMembros}
          dates={dates}
          setDates={setDates}
          status={status}
          setStatus={setStatus}
          checked={checked}
          setChecked={setChecked}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        {/* <Column>
                    <ObjectiveList />
                </Column> */}
        {/* {kanban ? (
          <>
            {datafilter()?.map((item, index) => {
              return (
                <Column key={index}>
                  <KanbanList item={item} />
                </Column>
              );
            })}
            <Column>
              <AddList />
            </Column>
          </>
        ) : (
          <LudzLoading />
        )} */}
        {filteredData ? <KanbanBoard datafilter={filteredData} /> : <LudzLoading />}

        {idcard && (
          <KanbanCardSubonaProvider>
            <InsiderCard
            kanban={kanban}
              visible={idcard ? true : false}
              onOpen={() => {
                history(`/zona/${id}/subzona/${idSubzone}/kanban`);
              }}
            />
          </KanbanCardSubonaProvider>
        )}
      </ObjectiveSubonaProvider>
    </Container>
  );
});

export default Kanban;
