import { createContext } from "react";
import { UserProfileState } from "./state";
import { UserProfileContextType } from "./type";

export const UserProfileContext = createContext<UserProfileContextType | null>(null);

interface Props {
    children: React.ReactNode
}

const UserProfileProvider = ({ children }: Props) => {
    const { userprofile, refetch, activeIndex, setActiveIndex, limitsize, limitallsize } = UserProfileState();

    return (
        <UserProfileContext.Provider value={{ userprofile, refetch, activeIndex, setActiveIndex, limitsize, limitallsize }}>
            {children}
        </UserProfileContext.Provider>
    )
}

export default UserProfileProvider