import { createContext } from "react";
import { CreateZoneState } from "./state";
import { CreateZonaContextType } from "./type";

export const CreateZonaContext = createContext<CreateZonaContextType | null>(null);

interface Props {
    children: React.ReactNode
}


const CreateZonaProvider = ({ children }: Props) => {

    const { initialValues, handleCreateZone, schema, toast } = CreateZoneState();


    return (
        <CreateZonaContext.Provider value={{ initialValues, handleCreateZone, schema, toast }}>
            {children}
        </CreateZonaContext.Provider>
    )
}

export default CreateZonaProvider;