import { useContext, useState } from "react";

import { SessionZonaContext } from "../../../../../contexts/Zone/SessionZone/context";
import { SessionZonaContextType } from "../../../../../contexts/Zone/SessionZone/type";
import { ZonaContext } from "../../../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../../../contexts/Zone/Zone/type";
import { Icon, Tooltip } from "../../../../../ludz.one_ui";
import ConfirmationDialog from "../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { PropsGetBffSession } from "../../../../../ludzoneSDK/Zone/Session/type";
import { Column, Row } from "../../../../../styles/globalStyles";
import EditSession from "../EditSession";
import { useMediaQuery } from "@mui/material";

type PropsHeaderAccordion = {
  item: PropsGetBffSession;
};

const HeaderAccordion = ({ item }: PropsHeaderAccordion) => {
  const [visible, setVisible] = useState(false);
  const matches = useMediaQuery("(max-width: 520px)")


  const { DeleteSession, accordionDisa, setAccordionDisa } = useContext(
    SessionZonaContext
  ) as SessionZonaContextType;

  const [visibleEditSection, setVisibleEditSection] = useState(false);

  const { adminorowner } = useContext(ZonaContext) as ZonaContextType;

  return (
    <Column style={{ height: "36px" }} id="center">
      <Row id="space-between">

        <Tooltip title={item?.name}>
          <h4 className="vertical-align-middle">
            {matches ? item.name.substring(0, 16) + '...' : item!.name}
          </h4>
        </Tooltip>
        {adminorowner ? (
          <Row>
            <Column id="center">
              <Icon
                size="1rem"
                onClick={(e: any) => {
                  e.stopPropagation();
                  e.preventDefault()
                  setVisibleEditSection(!visibleEditSection);
                  setAccordionDisa(!accordionDisa);
                }}
                icon="pi pi-pencil mr-2"
              ></Icon>
            </Column>
            <Padding />
            <Column id="center">
              <Icon
                size="1rem"
                onClick={(e: any) => {

                  e.stopPropagation();
                  e.preventDefault()
                  setVisible(true);
                }}
                icon="pi pi-trash mr-2"
              ></Icon>
            </Column>
          </Row>

        ) : null}
      </Row>
      <ConfirmationDialog
        accept={() => DeleteSession!(item.id)}
        visible={visible}
        reject={() => setVisible(true)}
        message="Deseja Excluir?"

        onHide={() => {
          setVisible(false);
          setAccordionDisa(!accordionDisa);
        }}
      />
      <EditSession
        item={item}
        onOpen={() => {
          setVisibleEditSection(!visibleEditSection);

          setAccordionDisa(!visibleEditSection);
        }}
        visible={visibleEditSection}
      />
    </Column>
  );
};

export default HeaderAccordion;
