import { useMutation } from "react-query";
import { PropsSubzoneObjectiveKey } from "./type";
import { useFetchSubzoneObjectiveKey } from "./query";
import { DeleteSubzoneObjectiveKeyRequest, SubzoneObjectiveKeyRequest, UpdateChangeIndexObjectiveKeyRequest, UpdateSubzoneObjectiveKeyRequest } from "./request";
import queryClient from "../../../../services/react-query";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../../components/Alert";

export const ObjectiveKeySubzoneController = (id: number, {
  toast,
}: {
  toast?: RefObject<Toast>;
}) => {

  const { data: objectivekeyRequests } = useFetchSubzoneObjectiveKey(id)

  const ObjectiveKeySubzoneMutation = useMutation((data: PropsSubzoneObjectiveKey) => SubzoneObjectiveKeyRequest(data), {
    onMutate: () => {
      //   setLoading(true);
      //   queryClient.refetchQueries()
      queryClient.refetchQueries("UseSubzoneObjectiveBff");

    },
    onError: (error: any) => {
       Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
      console.log(error)
    },
    onSuccess: (result) => {
      Alert({
        detail: "Meta criada!",
        severity: "success",
        summary: "Sucesso",
        toast: toast
      }).show();
      queryClient.refetchQueries("UseSubzoneObjectiveBff");
      // refetch()
    },
  });


  const ChangeIndexObjectivekeySubzoneMutation = useMutation(({body, id}: {body: {newIndex: number}, id: string}) => UpdateChangeIndexObjectiveKeyRequest(body, id), {
    onMutate: () => {
    //   setLoading(true);
    //   queryClient.refetchQueries()
    },
    onError: (error: any) => {
       Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
        console.log(error)
    },
    onSuccess: (result) => {
      Alert({
        detail: "Objetivo criado!",
        severity: "success",
        summary: "Sucesso",
        toast: toast
      }).show();
      queryClient.refetchQueries("UseSubzoneObjectiveBff");

    },
  });

  interface PropsUpdateObjetiveKey {
    body: { content: string, description: string },
    id: string
  }

  const UpdateObjectiveKeySubzoneMutation = useMutation(({ body, id }: PropsUpdateObjetiveKey) => UpdateSubzoneObjectiveKeyRequest(body, id), {
    onMutate: () => {
      //   setLoading(true);
      //   queryClient.refetchQueries()
      queryClient.refetchQueries("UseSubzoneObjectiveBff");

    },
    onError: (error: any) => {
       Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
      console.log(error)
    },
    onSuccess: (result) => {
      Alert({
        detail: "Meta editada!",
        severity: "success",
        summary: "Sucesso",
        toast: toast
      }).show();
      queryClient.refetchQueries("UseSubzoneObjectiveBff");

    },
  });

  const DeleteObjectiveKeySubzoneMutation = useMutation((id: string) => DeleteSubzoneObjectiveKeyRequest(id), {
    onMutate: () => {
    //   setLoading(true);
    //   queryClient.refetchQueries()
    },
    onError: (error: any) => {
       Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
        console.log(error)
    },
    onSuccess: (result) => {
      Alert({
        detail: "Meta excluida!",
        severity: "success",
        summary: "Sucesso",
        toast: toast
      }).show();
      queryClient.refetchQueries("UseSubzoneObjectiveBff");
    },
  });


  return {
    ObjectiveKeySubzoneMutation, objectivekeyRequests, UpdateObjectiveKeySubzoneMutation, DeleteObjectiveKeySubzoneMutation, ChangeIndexObjectivekeySubzoneMutation
  }
}


