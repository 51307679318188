import { useContext } from "react";
import { Avatar, Button, Icon } from "../../..";
import { InviteZonaContext } from "../../../../contexts/Zone/InviteZone/context";
import { InviteZonaContextType } from "../../../../contexts/Zone/InviteZone/type";
import { ZonaContext } from "../../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../../contexts/Zone/Zone/type";
import { Column, Row } from "../../../../styles/globalStyles";
import { Padding } from "../../../styles/styled-components";
import { Toast } from "primereact/toast";
import queryClient from "../../../../services/react-query";

interface PropsMember {
  id?: number;
  nome?: string;
  avatar?: string | null;
  isAdmin?: boolean;
  isMember?: any;
  onOpen?: any;
}

const UserZone = ({ avatar, id, isAdmin, nome, onOpen }: PropsMember) => {
  const { zone } = useContext(ZonaContext) as ZonaContextType;

  const { handleInvite, invites, handleDeleteInvite, toastInvite } = useContext(
    InviteZonaContext
  ) as InviteZonaContextType;

  const verifyMember = () => {
    const verify = zone!.zoneMembers.find((props) => props.user.id === id);
    return verify ? true : false;
  };

  
  const verifyInvite = () => {
    const inviteZone = invites!.filter((props) => props.zone_fk === zone?.id);
    const inviteUser = inviteZone!.find((props) => props.user_fk === id);
    return inviteUser;
  };

  const handleInviteAndCloseModal = () => {
    handleInvite({
      zone: zone!.id,
      description: "Convite para zona",
      fromLocal: 0,
      user: id!,
    });
    setTimeout(() => {
      queryClient.refetchQueries("UseZoneIdBff");
      queryClient.refetchQueries("UseAllInvite");
      queryClient.refetchQueries("UseUserInvites") // Fecha o modal
    }, 1500);

    // Verifique se o toastInvite.current não é null antes de usar
    if (toastInvite.current) {
      // console.log("Convite realizado!")
    }
  };

  const handleDeleteInviteAndCloseModal = () => {
    handleDeleteInvite(verifyInvite().id!)
    setTimeout(() => {
      queryClient.refetchQueries("UseZoneIdBff");
      queryClient.refetchQueries("UseAllInvite");
      queryClient.refetchQueries("UseUserInvites")
    }, 1500);

    // Verifique se o toastInvite.current não é null antes de usar
    if (toastInvite.current) {
      // console.log("Convite realizado!")
    }
  };

  return (
    <Padding padding="16px 0">
      <Toast ref={toastInvite} />
      <Row id="space-between">
        <Row>
          <Column id="center">
            <Avatar
              shape="circle"
              img={avatar ?? avatar}
              label={!avatar ? nome?.substring(0, 1) : null}
              size="normal"
              style={{ border: "1px solid #ccc" }}
            ></Avatar>
          </Column>
          <Column id="center">
            <h4>{nome}</h4>
          </Column>
        </Row>
        {isAdmin && (
          <Column id="center">
            <Icon icon="pi pi-star-fill" />
          </Column>
        )}
        {verifyMember() ? (
          <Column id="center">Já é membro</Column>
        ) : verifyInvite() ? (
          <Button
            severity="danger"
            onClick={handleDeleteInviteAndCloseModal}
            title="Cancelar"
          />
        ) : (
          <Button onClick={handleInviteAndCloseModal} title="Adicionar" />
        )}
      </Row>
    </Padding>
  );
};

export default UserZone;
