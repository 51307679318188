import http from "../../../../../services/api";
import { logout } from "../../../../../services/localStorage";
import { PropsMemberCard } from "./type";

export const SubzoneMemberKanbanCardRequest = async (body: PropsMemberCard) => {
    
    try {
        const response = await http.post("/direct/kanban-card-members", body);
        return response.data
    } catch (err: any) {
        if (err.response?.status === 401) {
            logout();
            window.location.reload();
        }
        throw err;
    }
    
}

export const DeleteMemberCardRequest = async (id: number) => {

    try {
        const response = await http.delete(`/direct/kanban-card-members/${id}`);
        return response.data
    } catch (err: any) {
        if (err.response.status === 401) {
            logout()
            window.location.reload()
        }
        alert(err.response.data.message)
        throw err; 
    }

}

