import { Checkbox } from "primereact/checkbox";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Progress } from "../../../../../../ludz.one_ui";
import { Padding } from "../../../../../../ludz.one_ui/styles/styled-components";
import { PropsSessionContent } from "../../../../../../ludzoneSDK/Zone/Session/type";
import { Column, Row } from "../../../../../../styles/globalStyles";
import EditTextSession from "../EditTextSession";
import { Inline } from "../style";
import { ContentCard } from "./style";
import { SessionZonaContext } from "../../../../../../contexts/Zone/SessionZone/context";
import { SessionZonaContextType } from "../../../../../../contexts/Zone/SessionZone/type";
import { useFetchZoneSessionsWatchedBff } from "../../../../../../ludzoneSDK/Zone/Session/WatchedSessionContents/query";
import { Toast } from "primereact/toast";

interface PropsContentText {
  item: PropsSessionContent;
  sessionContents: any;
  set: any;
}

const ContentText = ({ item, sessionContents, set }: PropsContentText) => {
  const { deleteSessionContentWatched, handleWatched, toastwatched } = useContext(
    SessionZonaContext
  ) as SessionZonaContextType;

  const [checked, setChecked] = useState(false);
  const { data } = useFetchZoneSessionsWatchedBff(item.id);

  useEffect(() => {
    if (data) {
      if (data?.session_content_fk) {
        setChecked(data?.session_content_fk === item.id);
      }
    }
  }, [data, item.id]);

  const [isEdit, setIsEdit] = useState(false);

  const [content, setContent] = useState<PropsSessionContent>();

  useEffect(() => {
    if (item) {
      setContent(item);
    }
  }, [item]);

  const history = useNavigate();

  const { id, idsessioncontent } = useParams();

  return (
    <div>
      {content ? (
        <>
          <ContentCard
            onClick={() => {
              history(`/zona/${id}/timeline/${item.id}`);
            }}
            active={item.id === parseInt(idsessioncontent!)}
          >
            <Toast ref={toastwatched} />
            <Padding padding="8px">
              <Row>
                <Column id="center">
                  <Checkbox
                    checked={checked}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!checked) {
                        handleWatched(item.id.toString());
                      } else {
                        deleteSessionContentWatched(item.id.toString());
                      }
                      setChecked(e.target.value);
                    }}
                  />
                </Column>
                <Padding />
                <h5>{item.title ?? "Sem titulo"}</h5>
              </Row>
            </Padding>
          </ContentCard>
          <Inline />
        </>
      ) : (
        <Progress />
      )}
      <EditTextSession
        visible={isEdit}
        onOpen={() => setIsEdit(!isEdit)}
        data={item}
        set={setIsEdit}
        setData={setContent}
      />
    </div>
  );
};

export default ContentText;
