import { useMutation } from "react-query";
import { PropsInvites } from "./type";
import { DeleteInviteZoneRequest, InviteZoneRequest } from "./request";
import { useFetchAllInvites } from "./query";
import queryClient from "../../../services/react-query";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../components/Alert";

export const InviteZoneController = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {

    const { data: allInvitesRequest, refetch } = useFetchAllInvites()

    const InviteZoneMutation = useMutation((data: PropsInvites) => InviteZoneRequest(data), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            if (error.response.data.message === 'The zone reached the maximum number of members allowed') {
                Alert({ summary: "Atenção", detail: "A zona atingiu o limite máximo de membros", severity: "warn", toast: toast }).show(); 
            }else{
                Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            }
             
            refetch()
            console.log(error)
        },
        onSuccess: (result: any) => {
            Alert({
                detail: "Convite realizado!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseZoneIdBff");
            queryClient.refetchQueries("UseAllInvite");
            queryClient.refetchQueries("UseUserInvites")

        },
    });

    const DeleteInviteZoneMutation = useMutation((id: number) => DeleteInviteZoneRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
             Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();

            console.log(error)
        },
        onSuccess: (result: any) => {
            Alert({
                detail: "Convite removido!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseZoneIdBff");
        queryClient.refetchQueries("UseAllInvite");
        queryClient.refetchQueries("UseUserInvites")
        },
    });



    return {
        InviteZoneMutation, allInvitesRequest, DeleteInviteZoneMutation
    }
}

