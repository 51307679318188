import { Toast } from "primereact/toast";
import { RefObject } from "react";
import { useMutation } from "react-query";
import { Alert } from "../../../components/Alert";
import queryClient from "../../../services/react-query";
import { DeleteSessionContentsZoneRequest } from "./InsiderSession/request";
import { ChangeIndexSessionZoneRequest, DeleteSessionZoneRequest, EditTextSessionZoneRequest, SessionZoneRequest } from "./request";
import { PropsChangeIndexSession, PropsSession } from "./type";

export const SessionZoneController = (id: number, {
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {

   


    const SessionZoneMutation = useMutation((data: PropsSession) => SessionZoneRequest(data), {
        onMutate: () => {
        },
        onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result: any) => {
            Alert({
                detail: "Seção criada!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseZoneSessionBff")
        },
    });


    const ChangeIndexSessionZoneMutation = useMutation((data: PropsChangeIndexSession) => ChangeIndexSessionZoneRequest(data), {
        onMutate: () => {
        },
        onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result: any) => {
              queryClient.refetchQueries("UseZoneSessionBff")
        },
    });


    interface PropsUpdateSession {
        data: {name: string},
        id: string
    }

    const UpdateSessionZoneMutation = useMutation(({data, id}: PropsUpdateSession) => EditTextSessionZoneRequest(data, id), {
        onMutate: () => {
        },
        onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result: any) => {
            Alert({
                detail: "Seção atualizada!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
              queryClient.refetchQueries("UseZoneSessionBff")
        },
    });
    

    const DeleteSessionContentsZoneMutation = useMutation((id: string) => DeleteSessionContentsZoneRequest(id), {
        onMutate: () => {
        },
        onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result: any) => {
            Alert({
                detail: "Conteudo excluido!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
              queryClient.refetchQueries("UseZoneSessionBff")
        },
    });

    const DeleteSessionZoneMutation = useMutation((id: string) => DeleteSessionZoneRequest(id), {
        onMutate: () => {
        },
        onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result: any) => {
            Alert({
                detail: "Seção excluida!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
              queryClient.refetchQueries("UseZoneSessionBff")
        },
    });
    

    return {
        SessionZoneMutation, DeleteSessionContentsZoneMutation, DeleteSessionZoneMutation, UpdateSessionZoneMutation, ChangeIndexSessionZoneMutation
    }
}