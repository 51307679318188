import { useContext, useState } from "react";
import { KanbanCardSubzonaContext } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { Icon } from "../../../../../../../ludz.one_ui";
import { Padding } from "../../../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../../../styles/globalStyles";
import ModalArchives from "./ModalArchives";
import ConfirmationDialog from "../../../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import ListArchives from "../../../../../../../components/Archives/ListArchives";


const ArchivesCard = () => {
  const { visibleArchives, setvisibleArchives, card, deleteArchives } =
    useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType;
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState();
  return (
    <Column>
      {card?.archives?.length > 0 && (
        <>
          {" "}
          <Row>
            <Row>
              <Column id="center">
                <Icon icon="pi pi-align-left" size="1rem" />
              </Column>
              <Padding padding="1px" />
              <Column id="center">
                <h3>Arquivos</h3>
              </Column>
            </Row>
            <Padding padding="1px" />
          </Row>
          <Padding />
          {card?.archives?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <Row id="space-between">
                  <Column>
                    <Padding />
                    <ListArchives key={index} item={item} />
                    <Padding />
                  </Column>
                  <Column id="center" className="text-black hover:text-red-500">
                    <Icon
                      size="1rem"
                      icon="pi pi-trash"
                      style={{ cursor: "pointer", marginRight: "4px" }}
                      onClick={() => {
                        setVisible(!visible);
                        setId(item?.id);
                      }}
                    />
                  </Column>
                </Row>
              </div>
            );
          })}
        </>
      )}
      <ConfirmationDialog
        accept={() => {
          deleteArchives(id!);
        }}
        visible={visible}
        reject={() => {
          setVisible(!visible);
        }}
        message="Deseja Excluir?"
        onHide={() => {}}
      />
      <ModalArchives
        visible={visibleArchives}
        onOpen={() => setvisibleArchives(!visibleArchives)}
      />
    </Column>
  );
};

export default ArchivesCard;
