import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100%;

  /* @media (max-width: 1024px) {
      height: 58vh;
    } */

  @media (max-width: 520px) {
    padding: 0px;
  }
`;

export const ChatBox = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  width: 100%;

  @media (max-width: 520px) {
    padding: 10px;
  }
`;

interface MessageProps {
  sender: "user" | "system" | "assistant";
}

export const Message = styled.div<MessageProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.sender === "user" ? "flex-end" : "flex-start"};
  margin-bottom: 10px;
`;

export const UserMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #7e63a9;
  color: white;
  padding: 10px;
  border-radius: 10px;
  max-width: 90%;

  p {
    white-space: pre-wrap; /* Adicionado para manter quebras de linha */
    overflow-wrap: break-word; /* Adicionado para tratar palavras longas */
    hyphens: auto;
  }


`;

export const IAMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e9ecef;
  color: black;
  padding: 10px;
  border-radius: 10px;
  max-width: 100%;

  p {
    white-space: pre-wrap; /* Adicionado para manter quebras de linha */
    overflow-wrap: break-word; /* Adicionado para tratar palavras longas */
    hyphens: auto;
  }

  .lottie-avatar {
    height: 30px;
    width: 30px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 50%;
    font-size: 1.4rem;
    transition: box-shadow 0.3s ease-in-out;
    flex-shrink: 0; /* Adicionado para evitar que a animação encolha */
  }

  /* MarkdownStyles.css */

.markdown-content {
  padding: 20px;
  overflow: auto;
  box-sizing: border-box;
  max-width: 100%;
  word-wrap: break-word;
}

@media (max-width: 520px) {
  .markdown-content{
    padding: 5px;
  }
}

`;

export const InputContainer = styled.div`
  
  margin-top: auto;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
`;

export const GridAwnsers = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Cria duas colunas
  grid-gap: 10px; // Espaçamento entre os itens do grid
  padding: 10px;

  p {
    font-size: medium;
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 520px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ButtonAlternative = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 1rem;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  gap: 10px;

  &:hover {
    background-color: #f6f7f7;
  }
`;
