import { Public, PublicOff } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { useContext, useRef, useState } from "react";
import PostZonaProvider from "../../../../contexts/Zone/MuralZone/context";
import { ZonaContext } from "../../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../../contexts/Zone/Zone/type";
import { ZonaInfoContext } from "../../../../contexts/Zone/ZoneInfo/context";
import { ZonaInfoContextType } from "../../../../contexts/Zone/ZoneInfo/type";
import {
  Avatar,
  Button,
  EditorText,
  Icon,
  Input,
  InputSwitch,
  Tooltip,
} from "../../../../ludz.one_ui";
import ButtonPrime from "../../../../ludz.one_ui/Buttons/ButtonPrime";
import ConfirmationDialog from "../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import EditAvatar from "../../../../ludz.one_ui/EditAvatar";
import { Padding, Row } from "../../../../ludz.one_ui/styles/styled-components";
import { getId } from "../../../../services/localStorage";
import { Column } from "../../../../styles/globalStyles";
import { Container } from "../../../style";
import ZonePainel from "./ZonePainel";
import * as C from "./styles";
import CreateTags from "./CreateTags";
import { TypeTagZone } from "../../../../ludzoneSDK/Zone/type";
import ChipTags from "./ChipTags";
import { Toast } from "primereact/toast";
import { FileUpload, FileUploadSelectEvent } from "primereact/fileupload";
import MeterGroup from "../../../../ludz.one_ui/MeterGroup";
import LudzLoading from "../../../../ludz.one_ui/Loading";

const ZoneInfo = () => {
  const { zone, adminorowner, HandleExitZone, toastZone, limitsize, limitallsize } = useContext(
    ZonaContext
  ) as ZonaContextType;
  const [visible, setvisible] = useState(false);
  const [visibleExit, setvisibleExit] = useState(false);
  const matchesMobileSmall = useMediaQuery("(max-width: 360px)");
  const [coverUrl, setCoverUrl] = useState(zone?.aws_2.url);

  const [removeAvatar, setRemoveAvatar] = useState<boolean | undefined>();
  const [removeFileCover, setRemoveCover] = useState<boolean | undefined>();

  const toastCover = useRef<Toast>(null);
  const [totalSize, setTotalSize] = useState(0);

  console.log(limitsize)
  console.log(limitallsize)

  const porcentlimitsize = 
  limitsize && limitallsize && !isNaN(parseFloat(limitsize)) && !isNaN(parseFloat(limitallsize))
    ? (parseFloat(limitsize) / parseFloat(limitallsize)) * 100
    : 0;

console.log(porcentlimitsize);

  const valuessize = [{ label: 'Espaço utilizado', value: porcentlimitsize, color: '#ff0000'  }];

  let names = `${zone?.name}`;
  var initialName = names.substring(0, 2);


  const {
    HandleSaveAvatarInfo,
    HandleSaveInfo,
    HandleRemoveAvatarInfo,
    HandleSaveCoverZone,
    HandleRemoveCoverZone,
    HandleDeleteZone,
    isediting,
    setIsEditing,
    setZoneDescription,
    setZoneName,
    zonedescription,
    initialValues,
    visibleTag,
    setVisibleTag,
    tags,
    toast,
    fileAvatar,
    setFileAvatar,
    fileCover,
    setFileCover,
  } = useContext(ZonaInfoContext) as ZonaInfoContextType;

  const edit = () => setIsEditing(true);

  const editorText = (e: any, setFieldValue: any) => {
    if (setFieldValue !== "") {
      setFieldValue("description", e.editor.getHTML());
    } else {
      setFieldValue("description", zonedescription);
    }
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setZoneName(zone?.name);
    setZoneDescription(zone?.description);
  };

  const NameRoomFormat = (name: any) => {
    if (name.length > 30) {
      return name.substring(0, 30) + "...";
    }
    return name;
  };

  const remove = () => {
    HandleRemoveAvatarInfo();
  };

  const removeCover = () => {
    HandleRemoveCoverZone();
  };

  const onTemplateSelect = (e: FileUploadSelectEvent) => {
    let _totalSize = totalSize;
    let files = e.files;

    for (let i = 0; i < files.length; i++) {
      _totalSize += files[i].size || 0;
    }
    if (files[0]?.size < 2000000) {
      setFileCover(files[0]);
      setCoverUrl(URL.createObjectURL(files[0]));
    } else {
      toastCover?.current?.show({
        severity: "warn",
        summary: "Error",
        detail: "Arquivo excede o limite permitido!",
      });
    }
    setTotalSize(_totalSize);
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  return (
    <Container>
      <Toast ref={toast} />
      <Toast ref={toastZone} />
      {zone && (
        <Column>
          <C.Container>
            {/* Usuários Administradores e Donos da Zona */}

            <Padding padding="8px" />
            <Formik
              initialValues={initialValues}
              onSubmit={async (value) => {
                await HandleSaveInfo(value);
                if (fileAvatar) {
                  await HandleSaveAvatarInfo(fileAvatar, setFileAvatar);
                }
                if (fileCover) {
                  await HandleSaveCoverZone(fileCover, setFileCover);
                }
                if (removeAvatar) {
                  await remove();
                  setRemoveAvatar(false);
                }
                if (removeFileCover) {
                  removeCover();
                  await setRemoveCover(false);
                }
              }}
            >
              {({
                values,
                handleChange,
                setFieldValue,
                handleSubmit,
                errors,
                touched,
              }) => {
                return (
                  <>
                    <form onSubmit={handleSubmit}>
                      <div className="grid">
                        <div className="col-12 lg:col-12">
                          <div className="card" style={{ height: "100%" }}>
                            <C.EditCover style={{ gap: "10px" }}>
                              <Column
                                style={{ alignItems: "center", gap: "8px" }}
                              >
                                {coverUrl && (
                                  <C.ImageCover
                                    src={coverUrl}
                                    alt="Cover Preview"
                                  />
                                )}
                              </Column>
                              {isediting && adminorowner && (
                                <Row
                                  style={{
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <Toast ref={toastCover} />
                                  <FileUpload
                                    mode="basic"
                                    name="cover"
                                    accept="image/*"
                                    onError={onTemplateClear}
                                    maxFileSize={2000000}
                                    onSelect={onTemplateSelect}
                                    chooseOptions={{
                                      label: "Alterar capa",
                                      className:
                                        "p-button-outlined p-button-plain",
                                    }}
                                  ></FileUpload>
                                  {isediting && adminorowner && (
                                    <>
                                      {removeFileCover ? (
                                        <h4>Capa removida! </h4>
                                      ) : (
                                        <Tooltip
                                          title="Remover"
                                          placement="bottom"
                                          arrow
                                        >
                                          <i
                                            onClick={() => {
                                              setRemoveCover(true);
                                              setCoverUrl("");
                                            }}
                                            className="pi pi-trash"
                                            style={{
                                              color: "red",
                                              fontSize: "1rem",
                                            }}
                                          ></i>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                </Row>
                              )}
                              <Padding />
                            </C.EditCover>
                            <Row style={{ marginBottom: "20px" }}>
                              <C.EditAvatar>
                                {isediting && adminorowner ? (
                                  <EditAvatar
                                    isediting={isediting}
                                    initialAvatarUrl={zone.aws.url}
                                    setImage={setFileAvatar}
                                  />
                                ) : (
                                  <Avatar
                                    shape="circle"
                                    size={
                                      matchesMobileSmall ? "large" : "xlarge"
                                    }
                                    label={
                                      !zone.aws.url ? initialName : undefined
                                    }
                                    img={
                                      zone.aws.url ? zone.aws.url : undefined
                                    }
                                    style={{ border: "1px solid #CCC" }}
                                  />
                                )}

                                {isediting && adminorowner && (
                                  <>
                                    {removeAvatar ? (
                                      <h4>Avatar removido!</h4>
                                    ) : (
                                      <Tooltip
                                        title="Remover"
                                        placement="bottom"
                                        arrow
                                      >
                                        <i
                                          onClick={() => setRemoveAvatar(true)}
                                          className="pi pi-trash"
                                          style={{
                                            marginTop: matchesMobileSmall
                                              ? "-16%"
                                              : "5px",
                                            marginRight: matchesMobileSmall
                                              ? "14px"
                                              : "0",
                                            color: "red",
                                            fontSize: "1rem",
                                          }}
                                        ></i>
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                              </C.EditAvatar>
                              <Padding />
                              <Column
                                id="center"
                                style={{
                                  marginLeft: matchesMobileSmall
                                    ? "-18px"
                                    : "0px",
                                  marginTop: matchesMobileSmall ? "-5%" : "0px",
                                  width: isediting ? "300px" : "auto",
                                }}
                              >
                                {isediting ? (
                                  <Input
                                    name="name"
                                    defaultValue={zone.name}
                                    value={values.name}
                                    onChange={handleChange}
                                    placeholder={zone.name}
                                    className={
                                      errors.name && touched.name
                                        ? "p-invalid"
                                        : ""
                                    }
                                  />
                                ) : (
                                  <Tooltip title={zone.name}>
                                    <h2
                                      className="edit-in-place"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          NameRoomFormat(zone.name) ||
                                          "Escreva Alguma coisa",
                                      }}
                                      style={{ wordBreak: "break-word" }}
                                    />
                                  </Tooltip>
                                )}
                                {errors.name && touched.name && (
                                  <div style={{ color: "red" }}>
                                    {errors.name}
                                  </div>
                                )}
                              </Column>
                              <C.AreaOptions>
                                <C.IconEdit>
                                  {zone.isPublic ? (
                                    <Tooltip title="zona publica">
                                      <Public color="primary" />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="zona privada">
                                      <PublicOff color="disabled" />
                                    </Tooltip>
                                  )}
                                </C.IconEdit>
                                {adminorowner && (
                                  <Tooltip
                                    title="Editar zona"
                                    placement="bottom"
                                    arrow
                                  >
                                    <C.IconEdit color="green">
                                      <Icon
                                        style={{
                                          fontSize: matchesMobileSmall
                                            ? "1.2rem"
                                            : "1.5rem",
                                        }}
                                        icon="pi pi-pencil"
                                        onClick={edit}
                                      />
                                    </C.IconEdit>
                                  </Tooltip>
                                )}
                                {adminorowner && (
                                  <Tooltip
                                    title="Deletar zona"
                                    placement="bottom"
                                    arrow
                                  >
                                    <C.IconEdit color="red">
                                      <Icon
                                        onClick={() => setvisible(!visible)}
                                        icon="pi pi-trash"
                                        style={{
                                          fontSize: matchesMobileSmall
                                            ? "1.2rem"
                                            : "1.5rem",
                                        }}
                                      ></Icon>
                                    </C.IconEdit>
                                  </Tooltip>
                                )}
                                <Tooltip title="Sair da zona">
                                  <C.IconEdit color="red">
                                    <Icon
                                      icon="pi pi-sign-out"
                                      style={{
                                        fontSize: matchesMobileSmall
                                          ? "1.2rem"
                                          : "1.5rem",
                                      }}
                                      onClick={() => setvisibleExit(true)}
                                    />
                                  </C.IconEdit>
                                </Tooltip>
                              </C.AreaOptions>
                            </Row>

                            <Padding padding="0px 0px 10px 0px">
                             {porcentlimitsize !== undefined && porcentlimitsize !== null ? 
                             <>
                              <h5 className="mb-2">Consumo de upload</h5>
                              {valuessize.map((val, index) => (
                                <MeterGroup key={index} label={val.label} value={val.value} />
                              ))}
                             </>
                             : 
                             <LudzLoading style={{ width: '40px', height: '50px' }} />
                             }
                              </Padding>          

                            {/*Função da Tags*/}
                            <h5 className="card-title mb-2">Tags da zona</h5>
                            <div className="flex justify-center items-center flex-wrap gap-2">
                              {isediting && (
                                <Button
                                  type="button"
                                  icon="pi pi-plus"
                                  tooltip="Criar Tags"
                                  className="p-button-rounded"
                                  onClick={() => {
                                    setVisibleTag(!visibleTag);
                                  }}
                                ></Button>
                              )}
                              {tags && (
                                <>
                                  {tags?.map(
                                    (item: TypeTagZone, index: number) => (
                                      <ChipTags
                                        tags={item}
                                        adminorowner={adminorowner}
                                        isediting={isediting}
                                      />
                                    )
                                  )}
                                </>
                              )}
                            </div>
                            <CreateTags
                              visibleTag={visibleTag}
                              onOpen={() => setVisibleTag(!visibleTag)}
                              zone={zone.id}
                            />
                            <Padding padding="10px" />
                            {isediting && (
                              <>
                                <InputSwitch
                                  label="Esta Zona é Pública? *"
                                  name="isPublic"
                                  value={zone.isPublic}
                                  checked={values.isPublic === true}
                                  onChange={handleChange}
                                />
                                <Padding />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {isediting && (
                        <div>
                          <Padding />
                          <div className="card" style={{ marginBottom: 0 }}>
                            <h5>Descrição</h5>
                            <Padding />
                            <EditorText
                              value={zone?.description}
                              name="description"
                              onTextChange={(e: any) =>
                                editorText(e, setFieldValue)
                              }
                            />
                          </div>
                        </div>
                      )}

                      {errors.description && touched.description && (
                        <div style={{ color: "red" }}>
                          <Padding />
                          {errors.description}
                        </div>
                      )}

                      <div>
                        {isediting && adminorowner && (
                          <>
                            <Padding />
                            <C.AreaButtons>
                              <ButtonPrime
                                onClick={cancelEdit}
                                title="Cancelar"
                                severity="danger"
                                style={{ marginRight: "6px" }}
                              ></ButtonPrime>
                              <ButtonPrime
                                title="Salvar"
                                type="submit"
                              ></ButtonPrime>
                            </C.AreaButtons>
                          </>
                        )}
                      </div>
                    </form>
                  </>
                );
              }}
            </Formik>
            <Padding />
            {/*Implementando Painel Freya */}
            {!isediting && (
              <PostZonaProvider>
                <ZonePainel zone={zone} />
              </PostZonaProvider>
            )}
          </C.Container>
        </Column>
      )}
      <ConfirmationDialog
        accept={() => HandleDeleteZone()}
        visible={visible}
        reject={() => setvisible(true)}
        message="Deseja Excluir?"
        onHide={() => setvisible(false)}
      />
      <ConfirmationDialog
        accept={() => HandleExitZone(getId()!, zone!.id.toString())}
        visible={visibleExit}
        reject={() => setvisibleExit(true)}
        message="Deseja Sair?"
        onHide={() => setvisibleExit(false)}
      />
    </Container>
  );
};

export default ZoneInfo;
