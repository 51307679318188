import http from "../../../services/api"
import { logout } from "../../../services/localStorage";
import { uploadArchive, verifySizeZone } from "../../../services/upload-archives";
import { PropsZonePost, PropsZonePostComment, ZonaTypePost, ZonaTypePostComment } from "./type"

// Requisições da postagem na Zona

export const ZonePostRequest = async (body: PropsZonePost, file?: File) => {
    if (file) {
        return await verifySizeZone(file, body.zone).then((item) => {
            return uploadArchive(file, "/bff/zone-post-bff", body, "POST");
        }).catch((error) => {
            throw new Error("UploadLimitExceeded"); // Usando uma mensagem fixa e curta
        });
    }
    return await http.post("/bff/zone-post-bff", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout();
                window.location.reload();
            }
            alert(err.response.data.message);
            throw err;
        });
}


export const UpdateZonePost = async (id: string | null, body: ZonaTypePost) => {
    return await http.put(`/direct/zone-post/${id}`, body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        })
}

export const DeleteZonePost = async (id: string | null) => {
    return await http.delete(`/bff/zone-post-bff`, { params: { zonePostId: id } }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        })
}

// Requisição das informações da postagem

export const GetZonePostBffRequest = async (id: string | undefined) => {

    return await http.get(`/bff/zone-post-bff`, {
        params: {
            zoneId: id,
        }
    }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            throw err;
        });
}

export const GetZonePostRequest = async (id: number) => {
    return await http.get("/direct/zone-post", {
        params: {
            include: JSON.stringify({
                owner: true
            }),
            zone_fk: id,
        }
    }).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            throw err;
        });
}

// Requisições de Comentários

export const ZonePostCommentRequest = async (body: PropsZonePostComment) => {
    return await http.post("/bff/zone-post-comments-bff", body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        });
}

export const UpdateZonePostComment = async (id: string | null, body: ZonaTypePostComment) => {
    return await http.put(`/direct/zone-post-comments/${id}`, body).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        })
}

export const DeleteZonePostComment = async (id: string | null) => {
    return await http.delete(`/direct/zone-post-comments/${id}`,).then(response => response.data)
        .catch(err => {
            if (err.response.status === 401) {
                logout()
                window.location.reload()
            }
            alert(err.response.data.message)
            throw err;
        })
}