import { Form, Formik } from "formik";
import { useContext } from "react";
import { SessionZonaContext } from "../../../../../contexts/Zone/SessionZone/context";
import { SessionZonaContextType } from "../../../../../contexts/Zone/SessionZone/type";
import { Button, Dropdown, Input, Modal } from "../../../../../ludz.one_ui";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../../../styles/globalStyles";

interface PropsCreateSection {
  visible?: boolean;
  onOpen?: any;
  item?: any;
}

const EditSession = ({ visible, onOpen, item }: PropsCreateSection) => {
  const { handleEditSession, session, handleChangeIndexSession } = useContext(
    SessionZonaContext
  ) as SessionZonaContextType;

  const listSesstion = session?.map((sessio, index) => {
    return { name: item.index === index ? `${index + 1 + " - " + sessio.name! + " (Atual)"}` : `${index + 1 + " - " + sessio.name!} `, index: index }
  })

  return (
    <Modal visible={visible} onOpen={onOpen} title={"Editar Seção"}>
      <div onClick={(e) => { e.stopPropagation(); }}>

        <Formik
          initialValues={{ name: item.name ?? "", index: listSesstion?.find(props => props.index === item?.index) }}
          onSubmit={(values) => {
            handleEditSession({ name: values.name }, item.id);
            if (values.index?.index !== item?.index) {
              handleChangeIndexSession({ newIndex: values.index?.index!, sessionId: item.id })
            }
            onOpen()
          }}
        >
          {({ values, handleChange, errors, touched, handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Column>
                  <Input
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    label="Nome da seção"
                    onKeyDown={(e: any) => {
                      if (e.key === " ") {
                        e.stopPropagation(); // Impede a propagação do evento
                      }
                    }}
                    maxLength={40}
                  />
                  <p className="font-semibold">{values.name.length} / 40 caracteres</p>
                </Column>
                <Padding />
                {errors.name && touched.name ? (
                  <div style={{ color: "red" }}>{errors.name.toString()}</div>
                ) : null}
                <Padding padding="8px" />

                <label>
                  Posição do conteúdo
                </label>
                <Padding />
                <Dropdown options={listSesstion} name="index" value={values.index} onChange={handleChange} optionLabel="name" />
                <Padding padding="32px" />
                <Row id="center">
                  <Button
                    title="Salvar"
                    type="submit"
                  // onClick={(e: any) => {
                  //   e.stopPropagation();
                  //   onOpen();
                  // }}
                  />
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default EditSession;
