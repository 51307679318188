import { useMutation } from "react-query";
import queryClient from "../../../../../services/react-query";
import { PropsMetasCard } from "./type";
import {
  DeleteMetasCardRequest,
  SubzoneMetasKanbanCardRequest,
} from "./request";

export const MetasKanbanCardController = () => {
  const MetasKanbanCardSubzoneMutation = useMutation(
    (data: PropsMetasCard) => SubzoneMetasKanbanCardRequest(data),
    {
      onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
      },
      onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);
       console.log(error)
      },
      onSuccess: (result) => {
        
        queryClient.invalidateQueries("UseSubzoneKanbanbff");
        queryClient.invalidateQueries("UseSubzoneKanbanCard");
      },
    }
  );

  const DeleteKanbanMeteasCardSubzoneMutation = useMutation(
    (id: number) => DeleteMetasCardRequest(id),
    {
      onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
      },
      onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);
        console.log(error)
      },
      onSuccess: (result) => {
        queryClient.invalidateQueries("UseSubzoneKanbanbff");
        queryClient.invalidateQueries("UseSubzoneKanbanCard");
      },
    }
  );

  return {
    MetasKanbanCardSubzoneMutation,
    DeleteKanbanMeteasCardSubzoneMutation,
  };
};
