import React from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';

interface PropsCheckBox {
    checked: boolean,
    onChange?(event: CheckboxChangeEvent): void
    style?: React.CSSProperties
    disabled?: boolean
}

const CheckboxLudz =  ({checked, onChange, style, disabled}: PropsCheckBox) => {
    return(
        <Checkbox 
            checked={checked} onChange={onChange} style={{...style}} disabled={disabled}
        />
    )
}

export default CheckboxLudz
        