import { Formik } from "formik";
import { useContext } from "react";
import { CreateZonaContext } from "../../../contexts/Zone/CreateZone/context";
import { CreateZonaContextType } from "../../../contexts/Zone/CreateZone/type";
import { Button, EditorText, Input, InputSwitch } from "../../../ludz.one_ui";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Column, Row } from "../../../styles/globalStyles";
import { Container } from "../../style";
import { Toast } from "primereact/toast";

const CreateZoneScreen = () => {

    const { initialValues, handleCreateZone, schema, toast } = useContext(CreateZonaContext) as CreateZonaContextType;

    const editorText = (e: any, setFieldValue: any) => {
        setFieldValue("description", e.editor.getHTML())
    }
    return (
        <Container>
            <Toast ref={toast} />
            <Column style={{ width: "100%" }} className="card">
                <h1>Criar Zona</h1>
                <Padding padding="16px" />
                <Formik initialValues={initialValues} onSubmit={value => handleCreateZone(value)} validationSchema={schema}>
                    {({ values, handleChange, setFieldValue, handleSubmit, errors, touched }) => {

                        return (
                            <form onSubmit={handleSubmit} style={{overflowY: "auto"}}>
                                <Input
                                    label="Nome"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    placeholder="Digite o nome da zona"
                                    className={(errors.name && touched.name) ? "p-invalid" : ""}
                                />
                                <Padding />
                                {(errors.name && touched.name) && <div style={{ color: "red" }}>{errors.name}</div>}
                                <Padding padding="8px" />
                                <InputSwitch label="Esta Zona é Pública? *" name="isPublic" value={true} checked={values.isPublic === true} onChange={handleChange} />
                                <Padding padding="8px" />
                                <label>Descrição</label>
                                <Padding />
                                <EditorText
                                    value={values.description}
                                    name="description"
                                    onTextChange={(e: any) => editorText(e, setFieldValue)}
                                />
                                <Padding />
                                {(errors.description && touched.description) && <div style={{ color: "red" }}>{errors.description}</div>}
                                <Padding padding="2px" />
                                <Row id="end">
                                    <Row>
                                        <Button title="Criar" type="submit" />
                                    </Row>
                                </Row>
                                <Padding padding="8px" />
                            </form>
                        )
                    }}
                </Formik>
            </Column>
        </Container>
    )
}

export default CreateZoneScreen;