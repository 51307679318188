import { memo, useCallback, useContext, useState } from "react";
import { KanbanCardSubzonaContext } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { ButtonUser } from "../../../style";
import { Icon } from "../../../../../../../../../ludz.one_ui";

interface PropsUser {
    item: any;
}

const User = memo(({ item }: PropsUser) => {
    const { card, handleAddMember, deleteMemberCard } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType;

    // Estado local para membros
    const [localMembers, setLocalMembers] = useState(card?.members);
    const [pendingUpdate, setPendingUpdate] = useState(false);

    // Verificar se o membro está adicionado no estado local
    const isAdd = localMembers?.find((i: any) => i?.member?.id === item?.user?.id);

    // Função para adicionar membro localmente e enviar ao servidor
    const handleAdd = useCallback(() => {
        setPendingUpdate(true);
        const updatedMembers = [...localMembers, { member: item.user }]; // Adiciona membro localmente
        setLocalMembers(updatedMembers); // Atualiza visualmente

        try {
            handleAddMember(item?.user?.id, card?.id); // Salva no backend
        } catch (error) {
            // Reverte a alteração local caso haja erro
            setLocalMembers(localMembers);
        } finally {
            setPendingUpdate(false);
        }
    }, [item.user, localMembers, card.id, handleAddMember]);

    // Função para remover membro localmente e enviar ao servidor
    const handleRemove = useCallback(() => {
        setPendingUpdate(true);
        const updatedMembers = localMembers.filter((i: any) => i?.member?.id !== item?.user?.id); // Remove membro localmente
        setLocalMembers(updatedMembers); // Atualiza visualmente

        try {
            deleteMemberCard(isAdd?.id); // Remove no backend
        } catch (error) {
            // Reverte a alteração local caso haja erro
            setLocalMembers(localMembers);
        } finally {
            setPendingUpdate(false);
        }
    }, [localMembers, item?.user?.id, deleteMemberCard, isAdd?.id]);

    return (
        <>
            {isAdd ? (
                <ButtonUser onClick={handleRemove} disabled={pendingUpdate}>
                    {item?.user.name.length > 29 ? (
                        <h5>{item?.user.name.substring(0, 30)}...</h5>
                    ) : (
                        <h5>{item?.user.name}</h5>
                    )}
                    <Icon icon="pi pi-check" size="1rem" />
                    {pendingUpdate && <Icon icon="pi pi-spinner pi-spin" size="1rem" />}
                </ButtonUser>
            ) : (
                <ButtonUser onClick={handleAdd} disabled={pendingUpdate}>
                    {item?.user.name.length > 29 ? (
                        <h5>{item?.user.name.substring(0, 30)}...</h5>
                    ) : (
                        <h5>{item?.user.name}</h5>
                    )}
                    {pendingUpdate && <Icon icon="pi pi-spinner pi-spin" size="1rem" />}
                </ButtonUser>
            )}
        </>
    );
});

export default User;
