import { useMutation } from "react-query";
import { useFetchZonePostBff } from "./query";
import { DeleteZonePost, DeleteZonePostComment, UpdateZonePost, UpdateZonePostComment, ZonePostCommentRequest, ZonePostRequest } from "./request";
import { PropsZonePost, ZonaTypePost, ZonaTypePostComment } from "./type";
import queryClient from "../../../services/react-query";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../components/Alert";

export const MuralZoneController = (id: number, {
  toast,
}: {
  toast?: RefObject<Toast>;
}) => {

    const {data: postsRequests, refetch} = useFetchZonePostBff(id.toString())
    

    const PostZoneMutation = useMutation(({ body, file }: { body: PropsZonePost; file?: File }) => ZonePostRequest(body, file), {
      onMutate: () => {
          // setLoading(true);
          // queryClient.refetchQueries()
      },
      onError: (error: any) => {
          if (error.message === "UploadLimitExceeded") {
              Alert({
                  summary: "Atenção",
                  detail: "A zona excedeu o limite de upload",
                  severity: "warn",
                  toast: toast
              }).show();
          } else {
              Alert({
                  summary: "Erro",
                  detail: "Aconteceu algum problema, tente novamente",
                  severity: "error",
                  toast: toast
              }).show();
              console.log(error);
          }
      },
      onSuccess: (result) => {
          Alert({
              detail: "Postagem realizada!",
              severity: "success",
              summary: "Sucesso",
              toast: toast
          }).show();
          refetch();
      },
  });
  

      interface PropsUpdateZone {
        body: ZonaTypePost;
        id: string;
      }

      const UpdateZonePostMutation = useMutation(
        ({ body, id }: PropsUpdateZone) => UpdateZonePost(id, body),
        {
          onMutate: () => {
          },
          onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
          },
          onSuccess: (result, veriables, context) => {
            Alert({
              detail: "Postagem editada!",
              severity: "success",
              summary: "Sucesso",
              toast: toast
            }).show();
            queryClient.refetchQueries("UseZonePostIdBff")
          },
        }
      );

      interface PropsDeleteZone {
        id: string | null;
      }

      const DeletePostZoneMutation = useMutation(
        ({ id }: PropsDeleteZone) => DeleteZonePost(id),
        {
          onMutate: () => {
          },
          onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
          },
          onSuccess: (result, veriables, context) => {
            Alert({
              detail: "Postagem removida!",
              severity: "success",
              summary: "Sucesso",
              toast: toast
            }).show();
            queryClient.refetchQueries("UseZonePostIdBff")
          },
        }
      );

      // Controller de Comentários

      const PostZoneCommentMutation = useMutation((data: any) => ZonePostCommentRequest(data), {
        onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
          Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result) => {
          Alert({
            detail: "Comentário realizado!",
            severity: "success",
            summary: "Sucesso",
            toast: toast
          }).show();
            queryClient.refetchQueries("UseZonePostIdBff")
        },
      });

      interface PropsUpdateZonePostComment {
        body: ZonaTypePostComment;
        id: string;
      }

      const UpdateZonePostCommentMutation = useMutation(
        ({ body, id }: PropsUpdateZonePostComment) => UpdateZonePostComment(id, body),
        {
          onMutate: () => {
          },
          onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
          },
          onSuccess: (result, veriables, context) => {
            Alert({
              detail: "Comentário editado!",
              severity: "success",
              summary: "Sucesso",
              toast: toast
            }).show();
            queryClient.refetchQueries("UseZonePostIdBff")
          },
        }
      );

      interface PropsDeleteZonePostComment {
        id: string | null;
      }

      const DeletePostCommentZoneMutation = useMutation(
        ({ id }: PropsDeleteZonePostComment) => DeleteZonePostComment(id),
        {
          onMutate: () => {
          },
          onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
          },
          onSuccess: (result, veriables, context) => {
            Alert({
              detail: "Comentário removido!",
              severity: "success",
              summary: "Sucesso",
              toast: toast
            }).show();
            queryClient.refetchQueries("UseZonePostIdBff")
          },
        }
      );

    return{
        PostZoneMutation, postsRequests, UpdateZonePostMutation, DeletePostZoneMutation, PostZoneCommentMutation, UpdateZonePostCommentMutation, DeletePostCommentZoneMutation
    }
}

