import styled from "styled-components";

interface propstyles {
  checkMockup: any
}

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 4%;
  grid-area: "children";
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;


export const TitlePublicRoom = styled.h1`
  margin: 20px 0px  ;
  font-family: var(--font-familyS);

  @media (max-width: 520px) {
    font-size: 25px;
  }

  @media (max-width: 520px) {
    text-align: center;
    font-size: 22px;
  }

  @media (max-width: 360px) {
    font-size: 20px;
  }
`;


export const WrapLista = styled.div<propstyles>`
  display: grid;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 6px;
  grid-template-areas: "CD CD CD";
  justify-content: center;
  height: auto;
  width: 100%;
  grid-template-columns: ${(props) =>
    props.checkMockup ? "repeat(3, 33.5%)" : "repeat(1, 100%)"};


  @media (min-width: 1600px) and (max-width: 3500px) {
      grid-template-areas: "CD CD CD CD";
      grid-template-columns: ${(props) =>
    props.checkMockup ? "repeat(4, 25%)" : "repeat(1, 100%)"};
  }

  @media (max-width: 1100px) {
    grid-template-areas: "CD CD";
    grid-template-columns: ${(props) =>
      props.checkMockup ? "repeat(2, 52.5%)" : "repeat(1, 100%)"};
    padding-left: 2%;
    padding-right: 2%;
  }

    @media (max-width: 920px) {
    grid-template-areas: "CD CD";
    grid-template-columns: ${(props) =>
      props.checkMockup ? "repeat(2, 52.8%)" : "repeat(1, 100%)"};
  }

  @media (max-width: 700px) {
    grid-template-areas: "CD";
    padding-left: 0;
    grid-template-columns: ${(props) =>
      props.checkMockup ? "repeat(1, 90%)" : "repeat(1, 100%)"};
  }

  @media (max-width: 520px) {
    margin: auto;
    padding-right: 10px;
  }

  @media (max-width: 360px) {
    padding-right: 0px;
  }
`;