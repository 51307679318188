import { Grid, useMediaQuery } from "@mui/material";
import { memo, useContext, useState } from "react";
import { KanbanCardSubzonaContext } from "../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/context";
import { KanbanCardSubzonaContextType } from "../../../../../../contexts/Subzone/KanbanSubzone/InsiderCard/type";
import { Icon, Modal } from "../../../../../../ludz.one_ui";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import LudzLoading from "../../../../../../ludz.one_ui/Loading";
import { Padding } from "../../../../../../ludz.one_ui/styles/styled-components";
import { Column } from "../../../../../../styles/globalStyles";
import DescriptionCard from "./DescriptionCard";
import IsFinish from "./IsFinish";
import MemberCard from "./MembersCard";
import MetasCard from "./MetasCards";
import MoveCard from "./MoveCard";
import NameCard from "./NameCard";
import TagsCard from "./TagsCard";
import { ButtonFunctions, IconText } from "./style";
import CommentsCard from "./CommentsCard";
import ArchivesCard from "./ArchivesCard";
import ModalMembers from "./MembersCard/Modal";
import ModalTags from "./TagsCard/ModalTags";
import ModalMetas from "./MetasCards/modal";
import ModalChecklist from "./ChecklistsCard/ModalChecklist";
import ChecklistsCard from "./ChecklistsCard";
import ModalDate from "./DateCard/ModalDate";
import DateCard from "./DateCard";
import { Toast } from "primereact/toast";
import { PropsSubzoneKanbanBFF } from "../../../../../../ludzoneSDK/Subzone/Kanban/type";
interface PropsInsiderCard {
  visible: boolean;
  onOpen: any;
  kanban: PropsSubzoneKanbanBFF[] | undefined
}

const InsiderCard = memo(({ visible, onOpen, kanban }: PropsInsiderCard) => {
  const matchesMobile = useMediaQuery("(max-width:1070px)");

  const [visibleMove, setVisibleMove] = useState(false);



  const {
    card,
    visibleMember,
    setvisibleMember,
    visibleTags,
    setvisibleTags,
    visibleMetas,
    setvisibleMetas,
    visibleArchives,
    setvisibleArchives,
    handleChecklist,
    visibleChecklist,
    setvisibleChecklist,
    handleUpdateDate,
    visibleDate,
    setvisibleDate,
    toast
  } = useContext(KanbanCardSubzonaContext) as KanbanCardSubzonaContextType;

  const listName = kanban?.find((list) => 
    list.kanbanCards.some((kanbanCard) => kanbanCard.id === card?.id)
  )?.title;

  
  return (
    <Modal title={`Lista -> ${listName ? listName : '----'}`} visible={visible} onOpen={onOpen}>
      <Toast ref={toast} />
      {card ? (
        <>
          <Padding />
          <NameCard name={card?.title} />
          <Padding padding="8px" />
          <Grid container spacing={2} columns={16}>
            <Grid item xs={matchesMobile ? 16 : 12}>
              <Padding />
              {true && (
                <Column>
                  {card?.members?.length > 0 && (
                    <>
                      <MemberCard />
                      <Padding padding="8px" />
                    </>
                  )}
                  {card?.labels.length > 0 && (
                    <>
                      <TagsCard />
                      <Padding padding="8px" />
                    </>
                  )}
                  {card?.expectedDate && (
                    <>
                      <DateCard
                        card={card}
                        visible={visibleDate}
                        setvisible={setvisibleDate}
                      />
                      <Padding padding="8px" />
                    </>
                  )}
                  {card?.objectives.length > 0 && (
                    <>
                      <MetasCard />
                      <Padding padding="8px" />
                    </>
                  )}
                  {card?.checklists.length > 0 && (
                    <>
                      <ChecklistsCard />
                      <Padding padding="8px" />
                    </>
                  )}
                  <ArchivesCard />
                  <Padding padding="8px" />
                  <DescriptionCard description={card?.description} />
                  <Padding padding="8px" />
                  <CommentsCard />
                </Column>
              )}
            </Grid>
            <Grid item xs={matchesMobile ? 16 : 4}>
              <Padding padding="8px" />
              <IsFinish />
              <Padding padding="8px" />
              <h4>Adicionar</h4>
              <ButtonFunctions onClick={() => setvisibleMember(!visibleMember)}>
                {" "}
                <IconText>
                  <Icon size="1.2rem" icon="pi pi-users" />
                  <p>Membros</p>
                </IconText>
              </ButtonFunctions>
              <ButtonFunctions onClick={() => setvisibleTags(!visibleTags)}>
                {" "}
                <IconText>
                  <Icon size="1.2rem" icon="pi pi-tags" />
                  <p>Tags</p>
                </IconText>
              </ButtonFunctions>
              <ButtonFunctions onClick={() => setvisibleMetas(!visibleMetas)}>
                {" "}
                <IconText>
                  <Icon size="1.2rem" icon="pi pi-key" />
                  <p>Metas</p>
                </IconText>
              </ButtonFunctions>
              <ButtonFunctions
                onClick={() => setvisibleChecklist(!visibleChecklist)}
              >
                <IconText>
                  <Icon size="1.2rem" icon="pi pi-check-square" />
                  <p>Checklists</p>
                </IconText>
              </ButtonFunctions>
              <ButtonFunctions
                onClick={() => setvisibleArchives(!visibleArchives)}
              >
                {" "}
                <IconText>
                  <Icon size="1.2rem" icon="pi pi-file" />
                  <p>Arquivos</p>
                </IconText>
              </ButtonFunctions>
              <h4>Ações</h4>
              <ButtonFunctions onClick={() => setVisibleMove(!visibleMove)}>
                {" "}
                <IconText>
                  <Icon size="1.2rem" icon="pi pi-arrow-right" />
                  <p>Mover</p>
                </IconText>
              </ButtonFunctions>
              <ButtonFunctions onClick={() => setvisibleDate(!visibleDate)}>
                {" "}
                <IconText>
                  <CalendarMonth
                    fontSize="small"
                    style={{ color: "#808080" }}
                  />{" "}
                  <p>Data</p>
                </IconText>
              </ButtonFunctions>
              {/* <ButtonFunctions> <IconText><CheckBoxIcon fontSize="small" style={{ color: "#808080" }} /> <p>CheckList</p></IconText></ButtonFunctions>  */}
            </Grid>
            <ModalMetas visible={visibleMetas} setvisible={setvisibleMetas} />
            <ModalTags visible={visibleTags} setvisible={setvisibleTags} />
            <ModalMembers
              visible={visibleMember}
              setvisible={setvisibleMember}
            />
            <MoveCard
              card={card}
              visible={visibleMove}
              setvisible={setVisibleMove}
            />
            <ModalChecklist
              handleChecklist={handleChecklist}
              visible={visibleChecklist}
              setvisible={setvisibleChecklist}
            />
            <ModalDate
              handleUpdateDate={handleUpdateDate}
              visible={visibleDate}
              setvisible={setvisibleDate}
              dateTime={card.expectedDate}
            />
          </Grid>
        </>
      ) : (
        <LudzLoading />
      )}
    </Modal>
  );
});

export default InsiderCard;
